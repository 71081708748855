import Slider from '@material-ui/core/Slider';
import React, { useEffect, useState } from 'react';

import css from './RangeSlider.module.css';

interface IProps {
  'aria-label'?: string;
  labelFunction?: (value: number) => string;
  minValue: number;
  maxValue: number;
  onChange?: (value: number | number[]) => void;
  onChangeCommitted?: (value: number | number[]) => void;
  step?: number;
  defaultValue?: number[];
}

type TThumbComponent = React.ElementType<React.HTMLAttributes<HTMLSpanElement>>;

const ThumbComponent: TThumbComponent = props => {
  const { className: _unused, ...rest } = props; // Remove default className
  const thumbClassName =
    'absolute flex flex-row items-center justify-center transform last:-translate-x-full -translate-y-1/2 bg-white border border-primary-800 rounded-full h-7 w-7 hover:border-primary-900';
  const barClassName = 'mx-0.5 border-l border-primary-800 rounded h-2 hover:border-primary-900';

  return (
    <span className={`${thumbClassName} before-focus-style ${css.thumb}`} {...rest}>
      <span className={barClassName} />
      <span className={barClassName} />
      <span className={barClassName} />
    </span>
  );
};

const RangeSlider: React.FC<IProps> = ({
  ['aria-label']: ariaLabel,
  labelFunction,
  onChange,
  onChangeCommitted,
  minValue,
  maxValue,
  defaultValue,
  step,
}) => {
  const [value, setValue] = useState<number | number[]>([
    defaultValue?.[0] || minValue,
    defaultValue?.[1] || maxValue,
  ]);

  const handleChange = (_event: any, newValue: number | number[]) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const handleChangeCommitted = (_event: any, newValue: number | number[]) => {
    onChangeCommitted && onChangeCommitted(newValue);
  };

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="relative">
      <Slider
        classes={{
          track: css.track,
          root: css.root,
        }}
        min={minValue}
        max={maxValue}
        ThumbComponent={ThumbComponent}
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        aria-label={ariaLabel}
        getAriaValueText={labelFunction}
        valueLabelFormat={labelFunction}
        step={step}
      />
    </div>
  );
};

export default RangeSlider;
