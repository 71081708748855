import useSWR from 'swr';

import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';

import { TGuidedSearchComponent } from './types';

type TGuidedSearchResponse = {
  data_file: {
    components: TGuidedSearchComponent[];
  };
};

// TODO: investigate why this renders so many times
export const useGuidedSearchComponents = () => {
  return useSWR<TGuidedSearchResponse>(`${getCoreApi()}/bui/guided-search`, (url: string) =>
    apiRequest<TGuidedSearchResponse>({ url }),
  );
};
