export enum EGuidedSearchComponentType {
  ButtonGroupRadio = 'button_group_radio',
}

type QueryParam = {
  key: string;
  value: string;
};

type TGuidedSearchComponentFieldRow = {
  id: string;
  label: string;
  query_params: QueryParam[];
};

export type TGuidedSearchComponentField = {
  id: string;
  component_type: EGuidedSearchComponentType;
  properties: {
    rows: TGuidedSearchComponentFieldRow[];
    title?: string;
    description: string;
  };
};

export type TGuidedSearchComponent = {
  id: string;
  fields: TGuidedSearchComponentField[];
  properties: {
    title: string;
  };
};
