import {
  EHeadingStyleVariant,
  EModalHeaderTextAlignVariant,
  EModalSize,
  EModalVariants,
  Heading,
  Modal,
} from '@outdoorsyco/bonfire';
import dynamic from 'next/dynamic';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@/components/switchback/Loading';
import { EFilterCategories, ESearchFilters } from '@/constants/searchFilters';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { triggerDeliveryFilterFromAd } from '@/redux/modules/search';
import { TRootState } from '@/redux/rootReducer';
import { getSearchFormDeliveryFilter } from '@/redux/selectors/search/searchForm';
import { getIsDeliveryFilterTriggeredFromAd } from '@/redux/selectors/search/searchResults';
import { EDeliveryOption } from '@/services/types/core/delivery.types';

import { EDeliveryFilterStep } from '../FilterPills/FilterPillsContentContainer';
import DeliveryFilter from '../RentalFilters/DeliveryFilter/DeliveryFilter';
import { PriceFilterContainer } from '../RentalFilters/PriceFilter/PriceFilterContainer';
import FilterModalContent from './FilterModalContent';

const loading = () => (
  <div className="flex items-center justify-center w-full h-64">
    <Loading />
  </div>
);
loading.displayName = 'loader';

const RVTypeFilter = dynamic(
  () => import('@/components/route/search/RentalFilters/RVTypeFilter/RVTypeFilter'),
  { loading },
);
const MoreFilter = dynamic(
  () => import('@/components/route/search/RentalFilters/MoreFilter/MoreFilter'),
  { loading },
);

interface IProps {
  isModalOpen: boolean;
  handleDismiss: () => void;
  filterFunctions: Partial<
    Record<EFilterCategories, { onSubmit: () => void; onReset: () => void; onDismiss: () => void }>
  >;
  deliveryFilterStep?: EDeliveryFilterStep;
  setDeliveryFilterStep?: (step: EDeliveryFilterStep) => void;
}

const FilterModalContainer: React.FC<IProps> = ({
  isModalOpen,
  handleDismiss,
  filterFunctions,
  deliveryFilterStep = EDeliveryFilterStep.PICKUP,
  setDeliveryFilterStep,
}) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();
  const selectedFilter = useSelector<TRootState, EFilterCategories | null>(
    state => state.search.selectedFilter,
  );

  const [deliveryOption, setDeliveryOption] = useState<EDeliveryOption>(EDeliveryOption.PICKUP);

  const setCurrentDeliveryOption = useCallback(
    (option: EDeliveryOption) => setDeliveryOption(option),
    [],
  );

  const isDeliveryFilterTriggeredFromAd = useSelector(getIsDeliveryFilterTriggeredFromAd);
  const dispatch = useDispatch();
  const onDismissHandler = () => {
    if (selectedFilter) {
      filterFunctions?.[selectedFilter]?.onDismiss();
    }
    handleDismiss();
    isDeliveryFilterTriggeredFromAd && dispatch(triggerDeliveryFilterFromAd(false));
  };

  const onReset = () => {
    if (selectedFilter) {
      filterFunctions?.[selectedFilter]?.onReset();
    }
  };

  const onSubmit = () => {
    if (selectedFilter) {
      filterFunctions?.[selectedFilter]?.onSubmit();
    }
    handleDismiss();
    isDeliveryFilterTriggeredFromAd && dispatch(triggerDeliveryFilterFromAd(false));
  };

  const {
    [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromStore,
    [ESearchFilters.DELIVERY]: isDeliveryFromStore,
  } = useSelector(getSearchFormDeliveryFilter);

  const [disablePreviewButton, setDisablePreviewButton] = useState(
    isDeliveryFromStore && !deliveryAddressFromStore,
  );

  const isDisabled =
    (isDeliveryFromStore && !deliveryAddressFromStore) ||
    (disablePreviewButton && deliveryFilterStep === EDeliveryFilterStep.DELIVERY);

  useEffect(() => {
    if (deliveryFilterStep === EDeliveryFilterStep.PICKUP && disablePreviewButton) {
      setDisablePreviewButton(false);
    }
  }, [deliveryFilterStep, disablePreviewButton]);

  const disableButton = useCallback(() => {
    setDisablePreviewButton(true);
  }, []);
  const enableButton = useCallback(() => {
    setDisablePreviewButton(false);
  }, []);

  const renderFilter = () => {
    let filterComponent;

    switch (selectedFilter) {
      case EFilterCategories.TYPE:
        filterComponent = <RVTypeFilter />;
        break;
      case EFilterCategories.MORE:
        filterComponent = <MoreFilter />;
        break;
      case EFilterCategories.PRICE:
        filterComponent = <PriceFilterContainer show={isModalOpen} showConfirm={false} />;
        break;
      case EFilterCategories.DELIVERY:
        return (
          <FilterModalContent
            onSubmit={onSubmit}
            onBack={() => {
              setDeliveryFilterStep?.(EDeliveryFilterStep.PICKUP);
              onReset();
            }}
            showBackButton={deliveryFilterStep === EDeliveryFilterStep.DELIVERY}
            isDisabled={isDisabled}>
            <DeliveryFilter
              deliveryFilterStep={deliveryFilterStep}
              onReset={onReset}
              setDeliveryFilterStep={setDeliveryFilterStep}
              disableButton={disableButton}
              enableButton={enableButton}
              setCurrentDeliveryOption={setCurrentDeliveryOption}
            />
          </FilterModalContent>
        );
      default:
        return null;
    }

    return (
      <FilterModalContent onReset={onReset} onSubmit={onSubmit}>
        {filterComponent}
      </FilterModalContent>
    );
  };

  if (!selectedFilter) {
    return null;
  }

  const titleLabelsMap: Partial<Record<EFilterCategories, string>> = {
    [EFilterCategories.TYPE]: intl.formatMessage({
      defaultMessage: 'RV type',
      id: 'YqHpvR',
      description: 'Filter modal container: title - rv type',
    }),
    [EFilterCategories.MORE]: intl.formatMessage({
      defaultMessage: 'Filters',
      id: 'xbKNEd',
      description: 'Filter modal container: title - more',
    }),
    [EFilterCategories.PRICE]: intl.formatMessage({
      defaultMessage: 'Price per night',
      id: 'OYUhPd',
      description: 'Filter modal container: title - price per night',
    }),
    [EFilterCategories.DELIVERY]:
      // eslint-disable-next-line no-nested-ternary
      deliveryOption === EDeliveryOption.MOVING
        ? intl.formatMessage({
            defaultMessage: 'Getting it delivered',
            id: 'YmA/nF',
            description: 'Filter modal container: title - Getting it delivered',
          })
        : deliveryOption === EDeliveryOption.STATIONARY
          ? intl.formatMessage({
              defaultMessage: 'Getting it set up',
              id: 'HRVhvN',
              description: 'Filter modal container: title - Getting it set up',
            })
          : intl.formatMessage({
              defaultMessage: 'Getting the RV',
              id: 'WMQGyb',
              description: 'Filter modal container: title - Getting the RV',
            }),
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onDismissHandler}
      size={EModalSize.Medium}
      variant={isMobile ? EModalVariants.Bottom : undefined}
      className="z-[1400]">
      <Modal.Actions />
      <Modal.Header
        textAlign={
          isMobile ? EModalHeaderTextAlignVariant.Left : EModalHeaderTextAlignVariant.Center
        }
        inlineHeader
        className="flex flex-row gap-3">
        <Heading variant={EHeadingStyleVariant.H6}>{titleLabelsMap[selectedFilter]}</Heading>
      </Modal.Header>
      {renderFilter()}
    </Modal>
  );
};

export default FilterModalContainer;
