import { TRootState } from '@/redux/rootReducer';

export const getLocation = (state: TRootState) => state.listing.data?.location;

export const getVehicleType = (state: TRootState) => state.listing.data?.type;

export const getStayType = (state: TRootState) => state.listing.data?.stay?.type;

export const getStayLocationDescription = (state: TRootState) =>
  state.listing.data?.stay?.location_description;

export const getCampsiteCampgroundSiteMapUrl = (state: TRootState) =>
  state.listing.data?.campground?.site_map_image_url;
