import {
  Button,
  EButtonColorVariant,
  EHeadingStyleVariant,
  EModalFooterVariants,
  EModalSize,
  Heading,
  LinkButton,
  Modal,
} from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { GuidedSearchComponent } from './GuidedSearchComponent';
import { useGuidedSearchContext } from './GuidedSearchContext';

type TGuidedSearchModalProps = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
};

export const GuidedSearchModal = ({ isOpen, onClose, className }: TGuidedSearchModalProps) => {
  const intl = useIntl();

  const {
    goToPrevStep,
    goToNextStep,
    applySelection,
    currentStep,
    numberOfSteps,
    currentStepComponent,
    currentStepSelection,
    updateCurrentStepSelection,
  } = useGuidedSearchContext();

  const everyStepSelectionApplied = useMemo(() => {
    if (!currentStepComponent || !currentStepSelection) return false;
    return currentStepComponent.fields.every(field => currentStepSelection[field.id]);
  }, [currentStepComponent, currentStepSelection]);

  if (!currentStepComponent) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        applySelection();
      }}
      size={EModalSize.Small}
      className={className}>
      <Modal.Actions />

      <Modal.Header>
        <Heading variant={EHeadingStyleVariant.H5} className="text-center">
          {currentStepComponent.properties.title}
        </Heading>

        <div className="relative mx-0 my-6 md:mx-8 h-[4px]">
          <div className="absolute inset-0 bg-neutral-20" />

          <div
            className="absolute top-0 bottom-0 left-0 bg-green-500"
            style={{ width: `${(currentStep / numberOfSteps) * 100}%` }}
          />
        </div>
      </Modal.Header>

      <Modal.Content>
        <GuidedSearchComponent
          {...currentStepComponent}
          values={currentStepSelection || {}}
          onChange={updateCurrentStepSelection}
        />
      </Modal.Content>

      <Modal.Footer variant={EModalFooterVariants.Actions}>
        <LinkButton
          label={intl.formatMessage({ defaultMessage: 'Back', id: 'cyR7Kh' })}
          className={clsx({ invisible: !goToPrevStep })}
          onClick={goToPrevStep}
        />

        <Button
          label={intl.formatMessage({ defaultMessage: 'Next', id: '9+Ddtu' })}
          variant={EButtonColorVariant.Primary}
          onClick={() => (goToNextStep || applySelection)()}
          disabled={!everyStepSelectionApplied}
        />
      </Modal.Footer>
    </Modal>
  );
};
