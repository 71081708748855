import React from 'react';

import { ButtonShape } from '@/components/switchback/Button/Button';

import { ButtonFilledState, TButtonFilledState } from '../ButtonFilledState/ButtonFilledState';
import { ButtonWithLink, TButtonWithLinkProps } from '../ButtonWithLink/ButtonWithLink';

type TBillModuleStepButtonProps = {
  outlined?: boolean;
} & (TButtonFilledState | TButtonWithLinkProps);

export const BillModuleStepButton = ({ outlined, ...props }: TBillModuleStepButtonProps) => {
  return (
    <>
      {outlined ? (
        <ButtonWithLink shape={ButtonShape.rectangle} {...props} />
      ) : (
        <ButtonFilledState {...props} />
      )}
    </>
  );
};
