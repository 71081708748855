import React from 'react';

import css from './Histogram.module.css';

interface IProps {
  values: number[];
  minValue?: number;
  maxValue: number;
  highlightRange?: {
    min: number;
    max: number;
  };
}

const Histogram: React.FC<IProps> = ({ values, minValue = 0, maxValue, highlightRange }) => {
  if (!values?.length) {
    return null;
  }
  const higherValue = Math.max(...values);
  const interval = (maxValue - minValue) / values.length;
  const firstHighlightedIndex = highlightRange ? (highlightRange.min || minValue) / interval : 0;
  const lastHighlightedIndex = highlightRange
    ? (highlightRange.max || maxValue) / interval - 1
    : values.length;

  return (
    <div aria-hidden="true" className="flex items-end justify-between w-full h-full">
      {values.map((value, index) => {
        const percent = (value / higherValue) * 100;
        const highlighted = index >= firstHighlightedIndex && index <= lastHighlightedIndex;

        return (
          <div
            key={index}
            style={{ height: `${Math.round(percent)}%`, minHeight: 1 }}
            data-highlighted={String(highlighted)}
            className={`mx-px w-2 bg-gray-200 transition-all duration-200 ${css.item}`}
          />
        );
      })}
    </div>
  );
};

export default Histogram;
