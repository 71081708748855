import {
  AlignType,
  ETextStyleVariant,
  Selector,
  SelectorGroup,
  SelectorType,
  Text,
} from '@outdoorsyco/bonfire';
import React from 'react';

import { EGuidedSearchComponentType, TGuidedSearchComponentField } from './types';

type TGuidedSearchComponentFieldProps = TGuidedSearchComponentField & {
  value?: string | string[];
  onChange: (value?: string | string[]) => void;
};

export const GuidedSearchComponentField = ({
  component_type,
  properties: { rows, title, description },
  value,
  onChange,
}: TGuidedSearchComponentFieldProps) => {
  if (component_type === EGuidedSearchComponentType.ButtonGroupRadio) {
    return (
      <FieldWrapper title={title}>
        <SelectorGroup
          ariaLabel={description}
          value={(value as string) || ''}
          onChange={onChange as (value: string) => void}
          selectorType={SelectorType.Single}>
          {rows.map(row => {
            return (
              <Selector
                key={row.id}
                name={row.id}
                value={row.id}
                ariaLabel={row.label}
                label={row.label}
                alignVariant={AlignType.VerticalCenter}
              />
            );
          })}
        </SelectorGroup>
      </FieldWrapper>
    );
  }

  throw new Error('Unsupported component type!');
};

type TFieldWrapperProps = {
  title?: string;
  children: JSX.Element;
};

const FieldWrapper = ({ title, children }: TFieldWrapperProps) => {
  return (
    <div className="flex flex-col gap-3">
      {title && <Text variant={ETextStyleVariant.SmallRegular}>{title}</Text>}
      {children}
    </div>
  );
};
