import React from 'react';

import { EDeliveryOption } from '@/services/types/core/delivery.types';

import { MovingDeliveryFilterDetail } from './MovingDeliveryFilterDetail';
import { StationaryDeliveryFilterDetail } from './StationaryDeliveryFilterDetail';

interface IDeliveryFilterOptionDetailsProps {
  deliveryType: string;
  deliveryAddressFromQuery: string;
  disableButton?: () => void;
  enableButton?: () => void;
}
export const DeliveryFilterOptionDetails = ({
  deliveryType,
  deliveryAddressFromQuery = '',
  disableButton,
  enableButton,
}: IDeliveryFilterOptionDetailsProps) => {
  const stationaryDelivery = deliveryType === EDeliveryOption.STATIONARY;
  return (
    <div className="flex flex-col">
      {stationaryDelivery ? (
        <StationaryDeliveryFilterDetail
          deliveryAddressFromQuery={deliveryAddressFromQuery}
          disableButton={disableButton}
          enableButton={enableButton}
        />
      ) : (
        <MovingDeliveryFilterDetail
          deliveryAddressFromQuery={deliveryAddressFromQuery}
          disableButton={disableButton}
          enableButton={enableButton}
        />
      )}
    </div>
  );
};
