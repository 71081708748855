import {
  Card,
  ECardBorderRadiusSize,
  ECardCarouselSize,
  ECardMediaSize,
  RawButton,
} from '@outdoorsyco/bonfire';
import React, { useCallback, useState } from 'react';

import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';
import { ICampgroundImage } from '@/services/types/search/campgrounds/id';

import { CampgroundImageLightBox } from './CampgroundImageLightBox';

interface ICampgroundImagesProps {
  images: ICampgroundImage[];
  name: string;
}
export const CampgroundImages = ({ images, name }: ICampgroundImagesProps) => {
  const [openLightBox, setOpenLightBox] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const handleImageClick = (imageId: number) => {
    const slideIndex = images.findIndex(image => image.id === imageId);
    setActiveSlide(slideIndex >= 0 ? slideIndex : 0);
    setOpenLightBox(true);
  };

  const closeLightBox = useCallback(() => setOpenLightBox(false), []);

  if (!images.length) return null;
  return (
    <>
      <Card
        borderRadiusSize={ECardBorderRadiusSize.Large}
        showHoverEffect={false}
        withBoxShadow={false}
        withTransparentBackground={true}
        aria-label={name}
        className="w-full h-full md:max-w-xs md:min-w-[372px]">
        {images.length > 1 && (
          <Card.Carousel
            content={
              // Show a maximum of 7 images
              images.slice(0, 7).map(image => (
                <RawButton
                  onClick={() => handleImageClick(image.id)}
                  className="rounded-[inherit]"
                  key={image.id}>
                  <ResponsiveImage
                    src={image.url}
                    cropMode="fill"
                    role="presentation"
                    sourceSizes={['landscape375', 'landscape572', 'landscape768']}
                    className="object-cover object-center w-full h-full"
                  />
                </RawButton>
              ))
            }
            size={ECardCarouselSize.Large}
            fullRadius
          />
        )}
        {images.length === 1 && (
          <Card.Media size={ECardMediaSize.Large} fullRadius className="bg-gray-200">
            {images.map(image => (
              <RawButton
                onClick={() => handleImageClick(image.id)}
                className="w-full rounded-[inherit] h-[inherit]"
                key={image.id}>
                <ResponsiveImage
                  src={image.url}
                  cropMode="fill"
                  role="presentation"
                  sourceSizes={['landscape375', 'landscape572', 'landscape768']}
                  className="object-cover object-center w-full h-full rounded-[inherit]"
                />
              </RawButton>
            ))}
          </Card.Media>
        )}
      </Card>

      <CampgroundImageLightBox
        isLightBoxOpen={openLightBox}
        closeLightBox={closeLightBox}
        images={images}
        activeSlide={activeSlide}
      />
    </>
  );
};
