import React, { Fragment } from 'react';

import GalleryItem from '@/components/route/listing/Gallery/GalleryList/GalleryItem';
import css from '@/components/route/listing/Gallery/GalleryList/GalleryList.module.css';
import Lightbox from '@/components/switchback/Lightbox';
import { ICampgroundImage } from '@/services/types/search/campgrounds/id';

type TCampgroundImageLightBoxProps = {
  isLightBoxOpen: boolean;
  activeSlide?: number;
  closeLightBox: () => void;
  images: ICampgroundImage[];
};
export const CampgroundImageLightBox = ({
  isLightBoxOpen,
  activeSlide = 0,
  closeLightBox,
  images,
}: TCampgroundImageLightBoxProps) => {
  return (
    <Lightbox
      infinite
      dots={false}
      adaptiveHeight
      headerPagination
      lazyLoad="ondemand"
      customModalClass={css.lightBoxModal}
      className={css.lightBox}
      open={isLightBoxOpen}
      initialSlide={activeSlide}
      disableAutoFocus
      disableEnforceFocus
      onClose={closeLightBox}>
      {images.map((image, index) => (
        <Fragment key={image.url}>
          <GalleryItem
            image={image.url}
            description={image.description}
            isVideo={image.video}
            index={index}
          />
          {image.description && <div className={css.description}>{image.description}</div>}
        </Fragment>
      ))}
    </Lightbox>
  );
};
