import { Overlay } from '@outdoorsyco/bonfire';
import React from 'react';
import { MenuTriggerState } from 'react-stately';

import css from './FilterDropdown.module.css';

interface INewFilterDropdownProps {
  show: boolean;
  triggerState: MenuTriggerState;
  triggerRef: React.RefObject<HTMLElement>;
  onDismiss: () => void;
}

const FilterDropdown: React.FC<React.PropsWithChildren<INewFilterDropdownProps>> = ({
  children,
  show,
  triggerState,
  onDismiss,
  triggerRef,
}) => {
  return (
    <>
      {show && (
        <Overlay
          triggerRef={triggerRef}
          triggerState={triggerState}
          popperProps={{
            placement: 'bottom',
          }}
          className="z-[1310]"
          underlay
          underlayClassname="z-[1299]"
          onDismiss={onDismiss}>
          <div className={`p-8 mt-2 bg-white shadow-100 rounded-box ${css.searchFilterWrapper}`}>
            {children}
          </div>
        </Overlay>
      )}
    </>
  );
};

export default FilterDropdown;
