import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Button, { ButtonSize, ButtonVariants } from '@/components/switchback/Button/Button';
import Icon from '@/components/switchback/Icon/Icon';
import { ICONS } from '@/components/switchback/Icon/Icon.constants';
import Link from '@/components/switchback/Link/Link';
import Modal from '@/components/switchback/Modal';
import { ModalHeight } from '@/components/switchback/Modal/Modal';
import { useBreakpoint } from '@/hooks/useBreakpoint';

interface IClampModalProps {
  modalContent?: React.ReactNode;
  lineCount?: number;
  showMoreText?: string;
  modalTitle?: string;
  isList?: boolean;
  hasMore?: boolean;
  ellipsis?: boolean;
}

const ClampModal: React.FC<React.PropsWithChildren<IClampModalProps>> = ({
  modalContent,
  children,
  lineCount = 3,
  showMoreText,
  modalTitle,
  isList = false,
  hasMore,
  ellipsis,
}) => {
  const [shouldShowAll, setShouldShowAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(true);
  const content = useRef<HTMLDivElement>(null);
  const renderedContent = useRef<HTMLDivElement>(null);
  const fullHeight = content.current?.scrollHeight || 0;
  const trimmedHeight = renderedContent.current?.offsetHeight || 0;

  useEffect(() => {
    if (hasMore === undefined && fullHeight > trimmedHeight) {
      setShowMoreButton(true);
    } else {
      setShowMoreButton(false);
    }
  }, [fullHeight, trimmedHeight, lineCount, hasMore]);

  const intl = useIntl();
  const { isMobile } = useBreakpoint();

  const showMore = intl.formatMessage({
    defaultMessage: 'Show more',
    id: 'aWpBzj',
  });

  const handleToggleAll = (event: React.MouseEvent) => {
    event.preventDefault();
    setOpen(true);
  };

  useEffect(() => {
    if (shouldShowAll) {
      if (isMobile) {
        setOpen(true);
        setShouldShowAll(false);
      } else {
        setOpen(false);
        setShouldShowAll(true);
      }
    }
  }, [isMobile, shouldShowAll]);

  return (
    <>
      <div ref={content} className="h-0 overflow-hidden">
        {children}
      </div>
      <div ref={renderedContent} className={ellipsis ? `line-clamp-${lineCount}` : ''}>
        {children}
      </div>
      {(hasMore || showMoreButton) && (
        <div className="mt-2 md:col-span-10">
          {!isMobile && isList ? (
            <Button
              variant={ButtonVariants.primaryOutlined}
              size={ButtonSize.xxsmall}
              className="focus-visible:before:border-transparent focus-visible:transition-none"
              onClick={handleToggleAll}>
              {showMoreText || showMore}
            </Button>
          ) : (
            <Link
              onClick={handleToggleAll}
              className="text-base font-bold semiHighlight"
              data-testid={shouldShowAll ? 'show-less-link' : 'show-all-link'}>
              {showMoreText || showMore}
              <Icon
                width={24}
                height={24}
                name={ICONS.CARET_SMALL}
                className="translate-y-0.5 -translate-x-1.5"
              />
            </Link>
          )}
        </div>
      )}
      <Modal
        title={modalTitle}
        titleContainerClass="mb-6"
        height={isMobile ? ModalHeight.full : ModalHeight.default}
        onClose={() => setOpen(false)}
        open={open}>
        {modalContent ?? children}
      </Modal>
    </>
  );
};

export default ClampModal;
