// eslint-disable-next-line @typescript-eslint/no-explicit-any
const inlineAddressFormat = (address: any) => {
  if (!address) {
    return '';
  }
  if (address.place_name) {
    return address.place_name;
  }
  return [
    [address.street, address.city].filter(val => val).join(' '),
    [address.state, address.zip].filter(val => val).join(' '),
    [address.country],
  ].join(', ');
};

export default inlineAddressFormat;
