import React, { createContext, PropsWithChildren, useContext } from 'react';

type TBillModuleContext = {
  readOnly: boolean;
};

const BillModuleContext = createContext<TBillModuleContext>({
  readOnly: false,
});

type TBillModuleProviderProps = {
  readOnly?: boolean;
};

export const BillModuleProvider = ({
  readOnly,
  children,
}: PropsWithChildren<TBillModuleProviderProps>) => {
  const value = { readOnly: !!readOnly };

  return <BillModuleContext.Provider value={value}>{children}</BillModuleContext.Provider>;
};

export const useBillModuleContext = () => useContext(BillModuleContext);
