import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

export type TFilterPreviewState = 'idle' | 'loading' | 'error';
interface IProps {
  onSubmit?: () => void;
  state: TFilterPreviewState;
  total: number | null;
  isDisabled?: boolean;
}

const FilterPreviewResults: React.FC<IProps> = ({ onSubmit, state, total, isDisabled }) => {
  const intl = useIntl();
  const resultsMessage = intl.formatMessage(
    {
      defaultMessage: 'Show {total, plural, one {# RV} other {# RVs}}',
      id: 'JZeWmw',
      description: 'Search > Filters: label with preview results',
    },
    { total },
  );
  const defaultMessage = intl.formatMessage({
    defaultMessage: 'Show RVs',
    id: 'iYo5qy',
    description: 'Search > Filters: default label',
  });
  const noResultsMessage = intl.formatMessage({
    defaultMessage: 'No results',
    id: 'Ukeqf0',
    description: 'Search > Filters: label with no results',
  });

  const handleSubmit = useCallback(() => {
    if (state === 'loading') return;
    onSubmit?.();
  }, [state, onSubmit]);

  return (
    <Button
      className="md:w-auto"
      onClick={handleSubmit}
      // eslint-disable-next-line no-nested-ternary
      label={total !== null ? (total > 0 ? resultsMessage : noResultsMessage) : defaultMessage}
      variant={EButtonColorVariant.Primary}
      loading={state === 'loading'}
      disabled={(state === 'idle' && total === 0) || isDisabled}
    />
  );
};

export default FilterPreviewResults;
