import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getBookingOrQuoteCurrency } from '@/redux/selectors/bill/data';
import {
  getDeliveryCostPerMile,
  getMeetsMinDeliveryCostReq,
  TRadius,
} from '@/redux/selectors/listing/delivery';
import { formatCurrency } from '@/utility/currency';
import { formatMeasurement } from '@/utility/measurements';
import { mapPluralUnitToSingular } from '@/utility/units';

import { DeliveryProposalFeeType } from './DeliveryContainer';

export const useDeliveryMessage = (
  deliveryRadius: TRadius,
  isPickUpSelected: boolean,
  hasAddress: boolean,
  distanceFromLocation?: number,
  deliveryCost?: string,
  deliveryFeeType?: DeliveryProposalFeeType,
  isProposalMode?: boolean,
  isNegotiating?: boolean,
) => {
  const distanceUnit = deliveryRadius && mapPluralUnitToSingular(deliveryRadius.unit);
  const meetsMinDeliveryCostReq = useSelector(getMeetsMinDeliveryCostReq);
  const parsedDeliveryRadius = formatMeasurement(deliveryRadius.radius || 0, {
    unit: distanceUnit || 'mile',
    long: true,
  });

  const intl = useIntl();
  const currency = useSelector(getBookingOrQuoteCurrency);
  const deliveryCostPerMile = useSelector(getDeliveryCostPerMile);
  const costPerMile = deliveryCostPerMile
    ? formatCurrency({ priceInCents: deliveryCostPerMile, currency, digits: 2 })
    : undefined;

  const formattedCostPerMile = intl.formatMessage(
    { defaultMessage: '{costPerMile} / {unit}', id: 'vTIXjG' },
    { costPerMile, unit: distanceUnit || 'mile' },
  );

  const footerMessages = {
    default: intl.formatMessage({
      defaultMessage: `You’ll receive the exact pick up location after your booking has been confirmed.`,
      id: 'dh0J94',
    }),
    doesNotMeetMinDeliveryCostReq: intl.formatMessage(
      { defaultMessage: `The host has set a minimum delivery fee of {deliveryCost}`, id: 'pKbBn1' },
      { deliveryCost },
    ),
    withDeliveryCost: intl.formatMessage(
      {
        defaultMessage: 'Your delivery fee is {deliveryCost}',
        id: 'hHWfB7',
      },
      {
        deliveryCost,
      },
    ),
    withoutDeliveryCost: intl.formatMessage(
      {
        defaultMessage:
          'Delivery fee will be calculated once you enter the delivery address. The host has a delivery radius of {parsedDeliveryRadius}.',
        id: '5lNANQ',
      },
      {
        parsedDeliveryRadius,
      },
    ),
    withoutDeliveryCostWithCampgrounds: intl.formatMessage(
      {
        defaultMessage:
          'Delivery fee will be calculated once you select a campground or enter your own delivery address. The host has a delivery radius of {parsedDeliveryRadius}.',
        id: 'U6EHts',
      },
      {
        parsedDeliveryRadius,
      },
    ),
    isMakingPickUpProposal: intl.formatMessage({
      defaultMessage: `You'll get a refund for any delivery fee already paid if the host approves this request.`,
      id: 'mePbkz',
    }),
    isMakingPreApprovalPickUpProposal: intl.formatMessage({
      defaultMessage: `We will remove any delivery fee from your pending booking total.`,
      id: 'h7M6LX',
    }),
    isMakingDeliveryProposal: intl.formatMessage({
      defaultMessage: `You'll see the delivery cost once you enter an address.`,
      id: '2L1wPo',
    }),
    deliveryDistanceProposal:
      distanceFromLocation &&
      intl.formatMessage(
        {
          defaultMessage: 'Distance from RV location: {distance}',
          id: 'UH79bB',
        },
        {
          distance: formatMeasurement(distanceFromLocation, { unit: distanceUnit, long: true }),
        },
      ),

    deliveryDistanceProposalCharge: intl.formatMessage(
      {
        defaultMessage: `Once the host approves your request, you will be charged a new delivery fee total of {deliveryCost}.`,
        id: 'R8CqLh',
      },
      { deliveryCost },
    ),
    insuranceFeeChange: intl.formatMessage(
      {
        defaultMessage: `Insurance fees has been adjusted to {deliveryCost}.`,
        id: '7f5LHf',
      },
      { deliveryCost },
    ),
    deliveryFeeChange: intl.formatMessage(
      {
        defaultMessage: `Delivery fee has been adjusted to {deliveryCost}.`,
        id: 'jOaYdI',
      },
      { deliveryCost },
    ),
    withoutDeliveryLocation: intl.formatMessage(
      {
        defaultMessage: `The host charges a delivery fee of {costPerMile} and has a delivery radius of {parsedDeliveryRadius}.`,
        id: 'wNZMbi',
      },
      { costPerMile: formattedCostPerMile, parsedDeliveryRadius },
    ),
  };

  const getFooterMessage = () => {
    if (isProposalMode) {
      if (isPickUpSelected) {
        if (isNegotiating) {
          return footerMessages.isMakingPreApprovalPickUpProposal;
        }

        return footerMessages.isMakingPickUpProposal;
      }

      if (!deliveryCost && !distanceFromLocation && !isPickUpSelected) {
        return footerMessages.isMakingDeliveryProposal;
      }

      if (deliveryCost && distanceFromLocation && !isPickUpSelected) {
        let message = '';
        if (isNegotiating) {
          message =
            deliveryFeeType === DeliveryProposalFeeType.INSURANCE_ONLY
              ? footerMessages.insuranceFeeChange
              : footerMessages.deliveryFeeChange;
        } else {
          message = footerMessages.deliveryDistanceProposalCharge;
        }

        return message;
      }
    }

    if ((!deliveryCost && !distanceFromLocation && !isPickUpSelected) || !hasAddress)
      return footerMessages.withoutDeliveryLocation;

    if (deliveryCost && distanceFromLocation && !isPickUpSelected)
      return meetsMinDeliveryCostReq
        ? footerMessages.withDeliveryCost
        : footerMessages.doesNotMeetMinDeliveryCostReq;

    return footerMessages.default;
  };

  return { getFooterMessage, deliveryDistanceProposalMsg: footerMessages.deliveryDistanceProposal };
};
