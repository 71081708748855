import { Modal } from '@outdoorsyco/bonfire';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import css from './AutocompleteMobile.module.css';

interface IProps {
  label: string;
  defaultValue?: string;
  panelTestId?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  inputPlaceholder?: string;
}

const AutocompleteMobile: React.FC<React.PropsWithChildren<IProps>> = ({
  label,
  children,
  defaultValue = '',
  panelTestId = 'autocomplete-mobile-panel',
  onChange,
  onClose,
  inputPlaceholder,
}) => {
  const [value, setValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;

    setValue(newValue);
    onChange(e);
  };

  const handleClickClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    setValue(defaultValue);
    onClose(e);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <Modal isOpen={true} className="!z-[1410]" onClose={() => handleClickClose}>
      <div
        data-testid={panelTestId}
        className={`fixed inset-0 py-5 bg-white flex flex-col ${css.container}`}>
        <div className="flex pb-2 mx-4 border-b-2 border-green-800">
          <div className="flex flex-col flex-1">
            <label htmlFor="autocomplete-mobile" className="text-xs text-gray-500 semiHighlight">
              {label}
            </label>
            <input
              className={css.input}
              name="autocomplete-mobile"
              id="autocomplete-mobile"
              onChange={handleChange}
              ref={ref => {
                if (inputRef.current) {
                  return;
                }

                inputRef.current = ref;
                ref?.focus();
              }}
              autoComplete="off"
              value={value}
              placeholder={inputPlaceholder}
            />
          </div>
          <button onClick={handleClickClose} className="ml-4 text-base text-gray-800 semiHighlight">
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </button>
        </div>
        <div className="flex-1 w-full overflow-y-auto">{children}</div>
      </div>
    </Modal>
  );
};

export default AutocompleteMobile;
