import noop from 'lodash/noop';
import React, { createContext, useCallback, useContext, useState } from 'react';

import { EFilterCategories } from '@/constants/searchFilters';
import { IPromoSearchFilters } from '@/services/types/promos/promos';

import FilterPillsContainer from '../FilterPills/FilterPillsContainer';

export enum EPromotionalFilterCategories {
  GUIDED_SEARCH = 'Guided Search',
  KEYWORD = 'Keyword',
  RATING = 'Rating',
  RULES_POLICIES = 'Rules and policies',
  VEHICLE_DETAILS = 'Vehicle details',
}
interface IFilterPillsContext {
  isAutocompleteOpen: boolean;
  setIsAutocompleteOpen: (isOpen: boolean) => void;
  hideFilter: (filter: EFilterCategories | EPromotionalFilterCategories) => boolean;
}

const FilterPillsContext = createContext<IFilterPillsContext>({
  isAutocompleteOpen: false,
  setIsAutocompleteOpen: noop,
  hideFilter: () => false,
});

export const useFilterPillsCtx = () => useContext(FilterPillsContext);

interface IFilterPillsCtxProps {
  promoSearchFilters?: IPromoSearchFilters;
  isPromoExperience?: boolean;
}
const FilterPillsCtx: React.FC<React.PropsWithChildren<IFilterPillsCtxProps>> = ({
  children,
  promoSearchFilters,
  isPromoExperience,
}) => {
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  // Legacy filter and new filter layout design is not a simple 1:1 mapping.
  // Some legacy filters were upgraded to "full filters", it's sort of arbitrary and could change.
  // This function is a mapping that normalizes the the legacy filter structure (which promo settings were designed on).
  const hideFilter = useCallback(
    (filter: EFilterCategories | EPromotionalFilterCategories): boolean => {
      // Only the promo experience needs to conditionally render filters right now.
      if (!isPromoExperience || !promoSearchFilters) return false;

      const { fullPromoFilters, partialPromoFilters } = promoSearchFilters;
      const hideAmenities = !!fullPromoFilters.hideAmenitiesFilter.isHidden;
      const hideDelivery = !!fullPromoFilters.hideDeliveryFilter.isHidden;
      const hideDrivable = !!fullPromoFilters.hideDrivableFilter.isHidden;
      const hideTowable = !!fullPromoFilters.hideTowableFilter.isHidden;
      const hidePrice = !!fullPromoFilters.hidePriceFilter.isHidden;
      const hideMake = !!fullPromoFilters.hideMakeFilter.isHidden;
      const hideMore = !!fullPromoFilters.hideMoreFilter.isHidden;
      // These are "partial features" since legacy filters just happened to include these
      // settings under other main filters... the distinction doesn't really matter.
      const hideInstantBook = !!partialPromoFilters.hideBookInstantlyFilter;
      const hideKeywordSearch = !!partialPromoFilters.hideKeywordSearchFilter;
      const hideRating = !!partialPromoFilters.hideMinimumRatingFilter;
      const hidePolicies = !!partialPromoFilters.hideMoreRulesPoliciesFilter;
      const hideVehicleDetails = !!partialPromoFilters.hideVehicleDetailsFilter;

      // Promo experience was not designed with guided search or pet-friendly in mind. Always hide it.
      if (filter === EPromotionalFilterCategories.GUIDED_SEARCH) return true;
      if (filter === EFilterCategories.PET_FRIENDLY) return true;
      // Type is now combined into one filter. So if we're hiding both, we hide this.
      // Otherwise we only hide the sections.
      if (filter === EFilterCategories.TYPE) return hideDrivable && hideTowable;

      // More filter might not explicitly be hidden, but EVERYTHING in it might be hidden
      // so we'd like to hide it in that additional case.
      if (filter === EFilterCategories.MORE) {
        const forceHideMore =
          hideKeywordSearch &&
          hideRating &&
          hidePolicies &&
          hideVehicleDetails &&
          hideAmenities &&
          hideMake;
        return hideMore || forceHideMore;
      }

      if (filter === EFilterCategories.INSTANT_BOOK) return hideInstantBook;
      if (filter === EFilterCategories.DELIVERY) return hideDelivery;
      if (filter === EFilterCategories.PRICE) return hidePrice;
      if (filter === EFilterCategories.DRIVABLE) return hideDrivable;
      if (filter === EFilterCategories.TOWABLE) return hideTowable;
      if (filter === EFilterCategories.AMENITIES) return hideAmenities;
      if (filter === EFilterCategories.MAKE) return hideMake;
      if (filter === EPromotionalFilterCategories.KEYWORD) return hideKeywordSearch;
      if (filter === EPromotionalFilterCategories.RATING) return hideRating;
      if (filter === EPromotionalFilterCategories.RULES_POLICIES) return hidePolicies;
      if (filter === EPromotionalFilterCategories.VEHICLE_DETAILS) return hideVehicleDetails;

      return false;
    },
    [promoSearchFilters, isPromoExperience],
  );

  return (
    <FilterPillsContext.Provider
      value={{
        isAutocompleteOpen,
        setIsAutocompleteOpen,
        hideFilter,
      }}>
      {children}
    </FilterPillsContext.Provider>
  );
};

export const FilterPillsWrapper: React.FC<IFilterPillsCtxProps> = props => (
  <FilterPillsCtx {...props}>
    <FilterPillsContainer />
  </FilterPillsCtx>
);
