import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import MapStatic, { IOverlay, IViewport } from '../MapStatic/MapStatic';

interface IPickupModalContentProps {
  zoom?: number;
  lat: number;
  lon: number;
  pin: string;
  listingLocation: string;
}

// max possible image size for each breakpoint
type TMapSize = { width: number; height: number };

export const PickupModalContent = ({
  zoom = 6,
  lat,
  lon,
  pin,
  listingLocation,
}: IPickupModalContentProps) => {
  const overlay: IOverlay = { lat, lon, url: pin };
  const viewport: IViewport = { lat, lon, zoom };
  const defaultSize: TMapSize = { width: 576, height: 200 };

  // Keep the same aspect ratio for the site map & the map
  const splitMapSizes: TMapSize[] = [defaultSize, { width: 700, height: Math.round(700 / 2.6) }];
  return (
    <>
      <MapStatic
        className="w-full overflow-hidden border border-gray-200 rounded-box"
        viewport={viewport}
        overlay={[overlay]}
        sourceSizes={splitMapSizes}
      />
      <div className="my-4">
        <Text variant={ETextStyleVariant.MediumRegular}>
          <FormattedMessage
            defaultMessage="Pick it up in {listingLocation}"
            id="xPkZ+d"
            values={{ listingLocation }}
          />
        </Text>

        <Text variant={ETextStyleVariant.SmallRegular} className="text-secondary-base mb">
          <FormattedMessage defaultMessage="Exact location provided after booking" id="UikgDr" />
        </Text>
      </div>
    </>
  );
};
