import React, { Component, ErrorInfo, ReactNode } from 'react';
import { useIntl } from 'react-intl';

const FallbackComponent = () => {
  const intl = useIntl();

  return (
    <div>
      {intl.formatMessage({
        defaultMessage: 'An error has occurred!',
        id: 'd6UQxE',
      })}
    </div>
  );
};
interface Props {
  children: ReactNode;
  fallBackComponent: ReactNode;
  onComponentDidCatch?: (error: Error, errorInfo: ErrorInfo) => void;
}

interface State {
  hasError: boolean;
}
declare global {
  interface Window {
    DD_RUM: any;
  }
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static defaultProps = {
    fallBackComponent: <FallbackComponent />,
  };
  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (window.DD_RUM) {
      window.DD_RUM.addError(error, { context: errorInfo.componentStack });
    }

    this.props.onComponentDidCatch?.(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallBackComponent;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
