import React from 'react';

import { breakpoint } from '@/constants/breakpoint';
import useWindowSize from '@/hooks/useWindowSize';

import FilterPreviewResultsContainer from '../FilterPreviewResultsContainer/FilterPreviewResultsContainer';
import FilterResetButton from '../FilterResetButton/FilterResetButton';
import FilterWithDivider from './FilterWithDivider';

interface IProps {
  divider?: boolean;
  title?: string;
  titleButton?: React.ReactNode;
  hasFooter?: boolean;
  onReset?: () => void;
  onSubmit?: () => void;
}

const FilterDropdownContent: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  divider = true,
  hasFooter = true,
  title,
  titleButton,
  onReset,
  onSubmit,
}) => {
  const windowSize = useWindowSize();

  return (
    <div className="md:-m-4">
      <FilterWithDivider divider={divider} title={title} titleButton={titleButton}>
        {children}
      </FilterWithDivider>
      {hasFooter && windowSize?.width && windowSize?.width > breakpoint.md && (
        <div className="flex justify-between mx-3">
          {onReset && <FilterResetButton onReset={onReset} />}
          <FilterPreviewResultsContainer onSubmit={onSubmit} />
        </div>
      )}
    </div>
  );
};

export default FilterDropdownContent;
