import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import GalleryItem from '@/components/route//listing/Gallery/GalleryList/GalleryItem';
import ClampModal from '@/components/route/listing/ClampModal/ClampModal';
import lightBoxCSS from '@/components/route/listing/Gallery/GalleryList/GalleryList.module.css';
import Heading from '@/components/switchback/Heading/Heading';
import Lightbox from '@/components/switchback/Lightbox';
import MapStatic, { IOverlay, IViewport } from '@/components/ui/MapStatic/MapStatic';
import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';
import { listingPageAnchors } from '@/constants/anchors';
import { ILocation as IListingLocation } from '@/services/types/search/rentals/id';
import { getStateName, TCountry } from '@/utility/location';

// max possible image size for each breakpoint
type TMapSize = { width: number; height: number };

const defaultSize: TMapSize = { width: 576, height: 360 };
const mapSizes: TMapSize[] = [
  defaultSize,
  { width: 700, height: 450 },
  { width: 920, height: 636 },
  { width: 1260, height: 450 },
];

// Keep the same aspect ratio for the site map & the map
const splitMapSizes: TMapSize[] = [
  defaultSize,
  { width: 700, height: Math.round(700 / 1.8) },
  { width: 920, height: Math.round(920 / 1.8) },
  { width: 1260, height: Math.round(1260 / 1.8) },
];

interface IProps extends Pick<IViewport, 'lat' | 'lon'> {
  listingLocation: IListingLocation;
  pin: string;
  zoom?: number;
  stayLocationDescription?: string;
  siteMapUrl?: string;
}

const Location: React.FC<IProps> = ({
  lat,
  lon,
  listingLocation,
  pin,
  zoom = 10,
  stayLocationDescription,
  siteMapUrl,
}) => {
  const intl = useIntl();

  const overlay: IOverlay = { lat, lon, url: pin };
  const viewport: IViewport = { lat, lon, zoom };
  const { state, country } = listingLocation;

  const stateName = getStateName(state, country.toUpperCase() as TCountry) ?? state;

  const location = `${listingLocation?.city}, ${stateName}`;

  const [isSiteMapPreviewOpen, setIsSiteMapPreviewOpen] = useState(false);

  return (
    <div className="relative" id={listingPageAnchors.LOCATION}>
      <div className="absolute -top-28" id={listingPageAnchors.LOCATION_HEADER} />
      <Heading level={2} className="mb-6 text-2xl highlight">
        {intl.formatMessage({
          defaultMessage: 'Location',
          id: 'AZ3iyH',
          description: 'route/listing/Location title w/o delivery',
        })}
      </Heading>
      <div className="relative -mx-4 md:mx-0">
        <div className={siteMapUrl ? 'grid md:grid-cols-2 gap-4' : ''}>
          <MapStatic
            className="w-full overflow-hidden border border-gray-200 md:rounded-box"
            data-testid="location-map"
            viewport={viewport}
            overlay={[overlay]}
            sourceSizes={siteMapUrl ? splitMapSizes : mapSizes}
          />

          {siteMapUrl && (
            <div className="relative">
              <ResponsiveImage
                src={siteMapUrl}
                alt={intl.formatMessage({ defaultMessage: 'Site map', id: 'ZUSnaX' })}
                className="object-cover w-full border border-gray-200 cursor-pointer aspect-[1.8] md:rounded-box"
                onClick={() => setIsSiteMapPreviewOpen(true)}
              />

              <Lightbox
                infinite
                dots={false}
                adaptiveHeight
                lazyLoad="ondemand"
                customModalClass={lightBoxCSS.lightBoxModal}
                className={lightBoxCSS.lightBox}
                open={isSiteMapPreviewOpen}
                onClose={() => setIsSiteMapPreviewOpen(false)}>
                <GalleryItem image={siteMapUrl} isVideo={false} index={0} />
              </Lightbox>
            </div>
          )}
        </div>
        <>
          <Heading level={5} className="relative mx-4 mt-2 mb-1 text-xl highlight md:mx-0">
            {location}
          </Heading>
          <p className="relative mx-4 mt-1 text-base text-secondary-base semiHighlight md:mx-0">
            {intl.formatMessage({
              defaultMessage: 'Exact location provided after booking',
              id: 'UikgDr',
            })}
          </p>
          {stayLocationDescription && (
            <ClampModal
              lineCount={2}
              modalTitle={intl.formatMessage({ defaultMessage: 'Location details', id: 'nZ19ry' })}
              ellipsis>
              <p className="mt-4 text-base">{stayLocationDescription}</p>
            </ClampModal>
          )}
        </>
      </div>
    </div>
  );
};

export default Location;
