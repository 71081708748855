import { Alert, EAlertVariant, ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { mapVehicleTypeToPin } from '@/components/route/listing/Location/LocationContainer';
import { CampgroundList } from '@/components/route/search/RentalFilters/DeliveryFilter/CampgroundList';
import { getFormattedCampgroundLocation } from '@/components/route/search/RentalFilters/DeliveryFilter/StationaryDeliveryFilterDetail';
import AddressInput from '@/components/switchback/AddressInput/AddressInput';
import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { TRadius } from '@/redux/selectors/listing/delivery';
import { getVehicleType } from '@/redux/selectors/listing/location';
import {
  EDeliveryFields,
  EDeliveryOption,
  TDeliveryFields,
} from '@/services/types/core/delivery.types';
import { IQuoteDeliveryLocation } from '@/services/types/quote/IDeliveryLocation';
import { ICampgroundData } from '@/services/types/search/campgrounds/id';
import { ILocation } from '@/services/types/search/rentals/id';
import { itemizeAddress } from '@/utility/itemizeAddress';
import { getStateName, TCountry } from '@/utility/location';

import { GettingRVOptionModal } from '../GettingRVOptionModal/GettingRVOptionModal';
import { DeliveryProposalFeeType } from './DeliveryContainer';
import { DeliveryFooterMessage } from './DeliveryFooterMessage';
import { DeliveryOptionTabs } from './DeliveryOptionTabs';
import { PickupModalContent } from './PickupModalContent';
import { useDeliveryMessage } from './useDeliveryMessage';

const STATE_PARK_WARNING_STATES = new Set(['CA', 'California']);

const getDefaultValues = ({
  address,
  estimatedDistance,
  location,
}: {
  address?: string;
  estimatedDistance?: number;
  location?: IQuoteDeliveryLocation;
}) => {
  if (!address) {
    return {
      [EDeliveryFields.DELIVERY_ADDRESS]: '',
      [EDeliveryFields.DELIVERY_LOCATION]: '',
      [EDeliveryFields.DELIVERY_DISTANCE]: '',
    };
  }

  return {
    [EDeliveryFields.DELIVERY_ADDRESS]: address,
    [EDeliveryFields.DELIVERY_DISTANCE]: estimatedDistance?.toString(),
    [EDeliveryFields.DELIVERY_LOCATION]: JSON.stringify(location),
  };
};

type IDeliveryModalElement = React.HTMLAttributes<HTMLElement>;

interface IProps {
  deliveryAddress?: string;
  deliveryCost?: string;
  deliveryRadius: TRadius;
  deliveryLocation?: IQuoteDeliveryLocation;
  deliverableCampgrounds?: ICampgroundData[] | null;
  deliveryCampgroundId?: number;
  distanceFromLocation?: number;
  error?: React.ReactNode;
  loading?: boolean;
  listingLocation?: ILocation;
  onClose: () => void;
  onSelectAddress: (value?: IQuoteDeliveryLocation) => void;
  /** confirm delivery address submission */
  onDeliverySubmit: (data: TDeliveryFields, isStationary: boolean) => void;
  onPickupSubmit: () => void;
  onAddressInputChange?: (resetDeliveryEstimate?: boolean) => void;
  deliveryType: EDeliveryOption;
  open: boolean;
  isProposalMode?: boolean;
  isSubmitting?: boolean;
  isNegotiating?: boolean;
  deliveryFeeType?: DeliveryProposalFeeType;
  onChangeDeliveryType: (type: EDeliveryOption) => void;
}

const DeliveryModal: React.FC<IProps & IDeliveryModalElement> = ({
  deliveryType,
  deliveryAddress,
  deliveryCost,
  deliveryLocation,
  deliveryRadius,
  deliverableCampgrounds,
  deliveryCampgroundId,
  distanceFromLocation,
  error,
  loading = false,
  listingLocation,
  onClose,
  onSelectAddress,
  onAddressInputChange,
  onDeliverySubmit,
  onPickupSubmit,
  open,
  isProposalMode = false,
  isSubmitting,
  isNegotiating = false,
  deliveryFeeType,
  onChangeDeliveryType,
}) => {
  const hasDeliverableCampgrounds = !!deliverableCampgrounds?.length;
  const intl = useIntl();
  const defaultValues = getDefaultValues({
    address: deliveryAddress,
    estimatedDistance: distanceFromLocation,
    location: deliveryLocation,
  });
  const form = useForm<TDeliveryFields>({
    mode: 'onSubmit',
    defaultValues,
  });
  const vehicleType = useSelector(getVehicleType);

  const selectedCampgroundId = form.watch(EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID);

  const [showDeliveryEstimate, setShowDeliveryEstimate] = useState<boolean>(true);
  const [selectedAddress, setSelectedAddress] = useState<IQuoteDeliveryLocation | undefined>();
  const [showStateParksWarning, setShowStateParksWarning] = useState<boolean>(false);
  // an error when no address is selected from autocomplete
  const [missingAddressError, setMissingAddressError] = useState(false);
  // tracks whether delivery address has changed when modal is opened
  const [hasChanged, setHasChanged] = useState(false);

  const isPickUpSelected = deliveryType === EDeliveryOption.PICKUP;
  const hasAddress = Boolean(deliveryAddress || selectedAddress);
  const validAddressError = intl.formatMessage({
    defaultMessage: `Please enter a valid address.`,
    id: 'gvBGct',
    description: 'UI > Delivery > Delivery option error',
  });

  useEffect(() => {
    form.setValue(EDeliveryFields.DELIVERY_ADDRESS, deliveryAddress || '');
    setShowStateParksWarning(
      (deliveryLocation?.state && STATE_PARK_WARNING_STATES.has(deliveryLocation?.state)) || false,
    );
    onAddressInputChange?.(true);
    setShowDeliveryEstimate(true);
    setHasChanged(false);
    setSelectedAddress(undefined);
    setMissingAddressError(false);
    form.setValue(EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID, deliveryCampgroundId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { getFooterMessage, deliveryDistanceProposalMsg } = useDeliveryMessage(
    deliveryRadius,
    isPickUpSelected,
    hasAddress,
    distanceFromLocation,
    deliveryCost,
    deliveryFeeType,
    isProposalMode,
    isNegotiating,
  );

  const footerMessage = getFooterMessage();

  const errorMessage = error || (missingAddressError ? validAddressError : '');
  const disableFooterButton = !isPickUpSelected && (Boolean(errorMessage) || !hasAddress);
  const hasSelectedCampground = deliveryType === EDeliveryOption.STATIONARY && selectedCampgroundId;
  const addressValue = hasSelectedCampground
    ? ''
    : (form.watch(EDeliveryFields.DELIVERY_ADDRESS) ?? '');

  useEffect(() => {
    form.setValue(
      EDeliveryFields.DELIVERY_DISTANCE,
      distanceFromLocation ? String(distanceFromLocation) : '',
    );
  }, [distanceFromLocation, form]);

  useEffect(() => {
    if (!form.getValues(EDeliveryFields.DELIVERY_LOCATION) && deliveryLocation) {
      form.setValue(EDeliveryFields.DELIVERY_LOCATION, JSON.stringify(deliveryLocation));
    }
  }, [deliveryLocation, form]);

  useEffect(() => {
    if (
      form.watch(EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID) &&
      deliveryType === EDeliveryOption.MOVING
    ) {
      form.setValue(EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID, 0);
    }
  }, [deliveryType, form]);

  useEffect(() => {
    if (!form.getValues(EDeliveryFields.DELIVERY_ADDRESS) && deliveryAddress && !open) {
      form.setValue(EDeliveryFields.DELIVERY_ADDRESS, deliveryAddress);
    }
  }, [deliveryAddress, form, open]);

  const handleDeliverySubmit = (data: TDeliveryFields) => {
    // prevents submission when address is modified
    if (!hasAddress && hasChanged) {
      setMissingAddressError(true);
      return;
    }

    setMissingAddressError(false);
    onClose();
    onDeliverySubmit(data, deliveryType === EDeliveryOption.STATIONARY);
  };

  const handlePickupSubmit = () => {
    onPickupSubmit();
    onClose();
  };

  const handleSelectedCampground = useCallback(
    (id: string) => {
      const campground = deliverableCampgrounds?.find(cg => cg.id === Number(id));
      if (!campground) return;
      const { campgroundAddress } = getFormattedCampgroundLocation(campground.location);
      form.setValue(EDeliveryFields.DELIVERY_ADDRESS, campgroundAddress || '');
      form.setValue(EDeliveryFields.DELIVERY_LOCATION, JSON.stringify(campground.location));
      form.setValue(EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID, Number(id));
      setHasChanged(true);
      setMissingAddressError(false);
      form.clearErrors(EDeliveryFields.DELIVERY_ADDRESS);

      onAddressInputChange?.();
      setSelectedAddress(campground.location);
      onSelectAddress(campground.location);
      setShowDeliveryEstimate(true);
    },
    [deliverableCampgrounds, form, onAddressInputChange, onSelectAddress],
  );

  const handleSelectAddress = useCallback(
    (option: IAutocompleteOption) => {
      const formattedAddress = itemizeAddress(option.value);
      setShowStateParksWarning(
        (formattedAddress.state && STATE_PARK_WARNING_STATES.has(formattedAddress.state)) || false,
      );
      form.setValue(EDeliveryFields.DELIVERY_ADDRESS, option.label);
      form.setValue(EDeliveryFields.DELIVERY_LOCATION, JSON.stringify(formattedAddress));
      form.setValue(EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID, 0);
      setHasChanged(true);
      setMissingAddressError(false);

      if (!option.value) {
        setSelectedAddress(undefined);
        return;
      }
      onAddressInputChange?.();
      setSelectedAddress(formattedAddress);
      onSelectAddress(formattedAddress);
      setShowDeliveryEstimate(true);
    },
    [form, onAddressInputChange, onSelectAddress],
  );

  const handleAddressInputChange = useCallback(() => {
    onAddressInputChange?.();
    // reset address selection, and remove error
    setHasChanged(true);
    setShowDeliveryEstimate(false);
    setMissingAddressError(false);
    setSelectedAddress(undefined);
    form.setValue(EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID, 0);
  }, [form, onAddressInputChange]);

  const handleSubmit = () => {
    if (isPickUpSelected) {
      return handlePickupSubmit();
    }

    if (hasSelectedCampground) {
      const data = form.getValues();
      return handleDeliverySubmit(data);
    }

    return form.handleSubmit(handleDeliverySubmit)();
  };

  if (!listingLocation) {
    return null;
  }
  const { lat, lng } = listingLocation;
  const pin = `https://d1o5877uy6tsnd.cloudfront.net/img/location-map-pins/map-pin-${
    (vehicleType && mapVehicleTypeToPin[vehicleType]) || mapVehicleTypeToPin.c
  }-v2.png`;

  const { state, country } = listingLocation;

  const stateName = getStateName(state, country.toUpperCase() as TCountry) ?? state;
  const location = `${listingLocation?.city}, ${stateName}`;

  return (
    <GettingRVOptionModal
      isOpen={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isSubmitting || loading}
      footerMessage={
        !!showDeliveryEstimate && (
          <DeliveryFooterMessage message={footerMessage} loading={loading} error={errorMessage} />
        )
      }
      isDisabled={disableFooterButton}>
      <DeliveryOptionTabs
        deliveryOption={deliveryType}
        changeDeliveryOption={onChangeDeliveryType}
      />

      {deliveryType === EDeliveryOption.PICKUP ? (
        <PickupModalContent lat={lat} lon={lng} pin={pin} listingLocation={location} />
      ) : (
        <>
          <Text variant={ETextStyleVariant.SmallRegular} className="mb-6">
            {deliveryType === EDeliveryOption.STATIONARY ? (
              <FormattedMessage
                defaultMessage="No driving necessary! Let the host deliver and set up the RV at a location below."
                id="Q8tRet"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Let the host deliver the RV to your house, the airport, or somewhere else, so you can hit the road from there."
                id="Gidb5M"
              />
            )}
          </Text>

          {deliveryType === EDeliveryOption.STATIONARY && hasDeliverableCampgrounds && (
            <>
              <CampgroundList
                campgroundList={deliverableCampgrounds}
                selectedCampgroundId={selectedCampgroundId}
                handleSelectedCampground={handleSelectedCampground}
              />
              <Text variant={ETextStyleVariant.MediumBold} className="mb-4">
                <FormattedMessage defaultMessage="Choose your destination" id="MIi32a" />
              </Text>
            </>
          )}

          {showStateParksWarning && (
            <Alert
              variant={EAlertVariant.Info}
              content={
                deliveryType === EDeliveryOption.MOVING
                  ? intl.formatMessage({
                      defaultMessage: 'RVs cannot be delivered to California State Parks.',
                      id: 'DFB9F8',
                    })
                  : intl.formatMessage({
                      defaultMessage: 'RVs cannot be set up to California State Parks.',
                      id: 'x1dBup',
                    })
              }
            />
          )}

          <form id="delivery-form">
            <div>
              <div className="mt-4 mb-6">
                <AddressInput
                  form={form}
                  required={!hasSelectedCampground}
                  addressValue={addressValue}
                  name={EDeliveryFields.DELIVERY_ADDRESS}
                  onChangeAddress={handleAddressInputChange}
                  onSelectAddress={handleSelectAddress}
                  placesOption={{
                    location: [lng, lat],
                    types: 'address,region,place,district,locality,poi',
                  }}
                  popupOptions={{
                    disablePortal: false,
                    anchorReference: 'anchorEl',
                    disableScrollLock: true,
                  }}
                  label={
                    deliveryType === EDeliveryOption.STATIONARY
                      ? intl.formatMessage({ defaultMessage: 'Set up location', id: 'OcMyLO' })
                      : intl.formatMessage({ defaultMessage: 'Delivery location', id: 'Flx2cd' })
                  }
                  popupClassName="p-8 shadow-100 rounded-box !z-[1450] mt-2"
                  skipFocusListener
                />
              </div>
              <input
                type="hidden"
                {...form.register(EDeliveryFields.DELIVERY_LOCATION, {
                  required: !hasSelectedCampground,
                })}
              />
            </div>

            {isProposalMode && distanceFromLocation && (
              <Text variant={ETextStyleVariant.LegalRegular} className="mt-4 text-gray-500">
                {deliveryDistanceProposalMsg}
              </Text>
            )}
          </form>
        </>
      )}
    </GettingRVOptionModal>
  );
};

export default DeliveryModal;
