import { useMemo, useState } from 'react';

export const useReMountableElementRef = <T extends HTMLElement | null>() => {
  const [element, setElement] = useState<T | null>(null);

  return {
    ref: useMemo(
      () => ({
        current: element,
      }),
      [element],
    ),
    element,
    setElement,
  };
};
