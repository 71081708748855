import React from 'react';

import css from './AspectRatio.module.css';

interface IAspectRatio {
  ratio: string;
  className?: string;
  hasImageHover?: boolean;
  isMobileRounded?: boolean;
}

const AspectRatio: React.FC<React.PropsWithChildren<IAspectRatio>> = ({
  ratio,
  className = '',
  hasImageHover = true,
  isMobileRounded = false,
  children,
}) => {
  const paddingBottom = ratio
    .split(':')
    .map(str => Number(str))
    .reduce((prev, curr) => curr / prev);

  const imageHover = hasImageHover ? css.imageHover : '';

  const isRounded = isMobileRounded ? 'sm:rounded' : '';

  return (
    <div
      className={`relative overflow-hidden ${isRounded} md:rounded ${className}`}
      style={{ paddingBottom: `${paddingBottom * 100}%` }}>
      <div className={`absolute inset-0 w-full h-full ${css.wrapper} ${imageHover}`}>
        {children}
      </div>
    </div>
  );
};

export default AspectRatio;
