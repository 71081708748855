import { EHeaderTab } from '@/components/ui/SearchHeader/SearchHeader';
import { ESearchFilters } from '@/constants/searchFilters';
import { parseFlexibleDateRange } from '@/redux/selectors/queryParams';
import { trackRenterSearchedEvent } from '@/services/analytics/search';
import { IRenterSearchedInfo } from '@/services/analytics/search/types';
import { ERentalType } from '@/services/analytics/types';
import { parseEventDate } from '@/services/analytics/utils';
import { LocationGroupType } from '@/utility/surfacedLocation';

export const trackRenterSearched = async (
  params: Record<string, string>,
  override?: Partial<IRenterSearchedInfo>,
) => {
  const address = params[ESearchFilters.ADDRESS];
  const from = params[ESearchFilters.DATE_FROM];
  const to = params[ESearchFilters.DATE_TO];
  const flexible = params[ESearchFilters.FLEXIBLE_DAYS];
  const adults = params[ESearchFilters.GUESTS_ADULTS];
  const children = params[ESearchFilters.GUESTS_CHILDREN];
  const feature = params[ESearchFilters.FILTER_FEATURE];

  await trackRenterSearchedEvent({
    source: 'guided_search_2',
    surfacedLocations: LocationGroupType.OTHER,
    totalAdults: Number(adults) || 0,
    totalChildren: Number(children) || 0,
    totalInfants: null,
    locationQuery: address || null,
    isADAAccessible: !!feature?.includes('accessible'),
    isPetFriendly: !!feature?.includes('pet_friendly'),
    flexibleDateRange: parseFlexibleDateRange(from, to, flexible),
    departureDate: (from && parseEventDate(from)) || null,
    returnDate: (to && parseEventDate(to)) || null,
    hasCategories: true,
    selectedCategory: EHeaderTab.ALL,
    towableYear: null,
    towableMake: null,
    towableModel: null,
    rentalType: ERentalType.RV,
    searchFilterCategory: ERentalType.RV,
    lastQuestionSeen: null,
    ...override,
  });
};
