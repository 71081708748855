import { ELinkColorVariant, ELinkVariant, ETextStyleVariant, Link } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

interface IProps {
  onReset: () => void;
}

const FilterResetButton: React.FC<IProps & React.HTMLAttributes<HTMLButtonElement>> = ({
  onReset,
}) => {
  const intl = useIntl();

  const defaultTitle = intl.formatMessage({
    defaultMessage: 'Reset',
    id: '/Uc10X',
    description: 'menu filter reset button',
  });

  return (
    <Link
      variant={ELinkVariant.Underline}
      onClick={onReset}
      target="_blank"
      colorVariant={ELinkColorVariant.Primary}
      textVariant={ETextStyleVariant.MediumBold}
      label={defaultTitle}
    />
  );
};

export default FilterResetButton;
