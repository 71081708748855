import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ESearchFilters } from '@/constants/searchFilters';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getNearbyCampgroundsForDelivery } from '@/redux/selectors/search/searchResults';

export const useSelectedCampground = () => {
  const campgroundList = useSelector(getNearbyCampgroundsForDelivery);

  const { [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: deliveryCampgroundIdFromQuery } =
    useSelector(getQueryParams);
  const selectedCampground = useMemo(
    () =>
      campgroundList?.find(campground => campground.id === Number(deliveryCampgroundIdFromQuery)),
    [campgroundList, deliveryCampgroundIdFromQuery],
  );

  return { selectedCampground };
};
