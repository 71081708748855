import {
  Button,
  EButtonColorVariant,
  ETextStyleVariant,
  Icon,
  Text,
  TIconName,
} from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import { EDeliveryOption } from '@/services/types/core/delivery.types';

type TDeliveryOptionTabsProps = {
  deliveryOption: EDeliveryOption;
  changeDeliveryOption: (option: EDeliveryOption) => void;
};
export const DeliveryOptionTabs = ({
  deliveryOption,
  changeDeliveryOption,
}: TDeliveryOptionTabsProps) => {
  const intl = useIntl();

  const dropdownOptions = [
    {
      label: intl.formatMessage({ defaultMessage: 'Pick it up', id: 'uCaDsz' }),
      icon: 'General.ManageVehicles',
      value: EDeliveryOption.PICKUP,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Delivery', id: 'drqP2L' }),
      icon: 'Amenities.Booking.BookerDeliveryOnly',
      value: EDeliveryOption.MOVING,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Set up', id: 'rrGMSx' }),
      icon: 'Amenities.Awning.Awning',
      value: EDeliveryOption.STATIONARY,
    },
  ];
  return (
    <div className="flex mt-2 mb-8 gap-2">
      {dropdownOptions.map(option => (
        <Button
          className="py-2 px-2.5 sm:px-4"
          key={option.value}
          label={
            <span className="flex items-center justify-center gap-1 md:gap-2">
              <Icon name={option.icon as TIconName} width={20} height={20} />
              <Text variant={ETextStyleVariant.SmallBold}>{option.label}</Text>
            </span>
          }
          onClick={() => changeDeliveryOption(option.value)}
          variant={
            deliveryOption === option.value
              ? EButtonColorVariant.Tertiary
              : EButtonColorVariant.Secondary
          }
        />
      ))}
    </div>
  );
};
