import { Divider, Icon, Radio, RadioGroup } from '@outdoorsyco/bonfire';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ESearchFilters } from '@/constants/searchFilters';
import { triggerStationaryDeliveryFilter } from '@/redux/modules/search';
import { clearFormFilter, EFilterMethod, setFormFilter } from '@/redux/modules/searchForm';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getSearchFormDeliveryFilter } from '@/redux/selectors/search/searchForm';
import { getIsStationaryFilterTriggered } from '@/redux/selectors/search/searchResults';
import { EDeliveryOption } from '@/services/types/core/delivery.types';

import { EDeliveryFilterStep } from '../../FilterPills/FilterPillsContentContainer';
import { DeliveryFilterOption } from './DeliveryFilterOption';
import { DeliveryFilterOptionDetails } from './DeliveryFilterOptionDetails';

interface INewDeliveryFilterProps {
  deliveryFilterStep: EDeliveryFilterStep;
  onReset: () => void;
  setDeliveryFilterStep?: (step: EDeliveryFilterStep) => void;
  disableButton?: () => void;
  enableButton?: () => void;
  setCurrentDeliveryOption: (option: EDeliveryOption) => void;
}
const DeliveryFilter = ({
  deliveryFilterStep,
  onReset,
  setDeliveryFilterStep,
  disableButton,
  enableButton,
  setCurrentDeliveryOption,
}: INewDeliveryFilterProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    [ESearchFilters.DELIVERY]: deliveryFromQuery,
    [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromQuery,
    [ESearchFilters.ADDRESS]: addressFromQuery,
    [ESearchFilters.DELIVERY_ADDRESS]: deliveryAddressFromQuery,
    [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: deliveryCampgroundIdFromQuery,
  } = useSelector(getQueryParams);

  const { [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromStore } = useSelector(
    getSearchFormDeliveryFilter,
  );

  const isStationaryFilterTriggered = useSelector(getIsStationaryFilterTriggered);

  const deliveryType: string = useMemo(() => {
    if (deliveryFromQuery !== 'true' && !deliveryStationaryFromStore) {
      return '';
    }

    return deliveryStationaryFromStore || '';
  }, [deliveryFromQuery, deliveryStationaryFromStore]);

  useEffect(() => {
    const currentDeliveryType = (deliveryType as EDeliveryOption) || EDeliveryOption.PICKUP;

    if (
      currentDeliveryType !== EDeliveryOption.PICKUP &&
      deliveryFilterStep === EDeliveryFilterStep.PICKUP
    ) {
      setDeliveryFilterStep?.(EDeliveryFilterStep.DELIVERY);
    }

    setCurrentDeliveryOption(currentDeliveryType);
  }, [deliveryFilterStep, deliveryType, setCurrentDeliveryOption, setDeliveryFilterStep]);

  const onDeliveryChange = useCallback(
    (type: EDeliveryOption) => {
      if (type === EDeliveryOption.MOVING) {
        dispatch(
          clearFormFilter({
            filters: [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID],
          }),
        );
      }
      dispatch(
        setFormFilter({
          filters: {
            [ESearchFilters.DELIVERY]: true,
            [ESearchFilters.DELIVERY_ADDRESS]: addressFromQuery as string,
            [ESearchFilters.DELIVERY_STATIONARY]: type,
          },
          filterMethod: EFilterMethod.RADIO,
        }),
      );
    },
    [dispatch, addressFromQuery],
  );

  const handleSelectPickup = useCallback(() => {
    if (!deliveryType) return;
    onReset();
  }, [deliveryType, onReset]);

  const handleSelectDelivery = useCallback(() => {
    setDeliveryFilterStep?.(EDeliveryFilterStep.DELIVERY);
    onDeliveryChange(EDeliveryOption.MOVING);
  }, [onDeliveryChange, setDeliveryFilterStep]);

  const handleSelectDeliveryAndSetup = useCallback(() => {
    setDeliveryFilterStep?.(EDeliveryFilterStep.DELIVERY);
    onDeliveryChange(EDeliveryOption.STATIONARY);
  }, [onDeliveryChange, setDeliveryFilterStep]);

  // Set an initial value
  useEffect(() => {
    dispatch(
      setFormFilter(
        {
          filters: {
            ...(deliveryFromQuery && {
              [ESearchFilters.DELIVERY]: deliveryFromQuery === 'true',
            }),
            ...(deliveryFromQuery === 'true' &&
              addressFromQuery && {
                [ESearchFilters.DELIVERY_ADDRESS]: addressFromQuery as string,
              }),
            ...(deliveryStationaryFromQuery && {
              [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromQuery as string,
            }),
            ...(deliveryCampgroundIdFromQuery && {
              [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: Number(deliveryCampgroundIdFromQuery),
            }),
          },
          filterMethod: null,
        },
        true,
      ),
    );

    // Run only on mount to sync state with query parameters.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const pickupLabel = intl.formatMessage({ defaultMessage: 'Pick it up', id: 'uCaDsz' });

  useEffect(() => {
    if (isStationaryFilterTriggered) {
      handleSelectDeliveryAndSetup();
      dispatch(triggerStationaryDeliveryFilter(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStationaryFilterTriggered]);

  return (
    <>
      {deliveryFilterStep === EDeliveryFilterStep.PICKUP ? (
        <>
          <DeliveryFilterOption
            label={pickupLabel}
            iconName="General.ManageVehicles"
            onClick={handleSelectPickup}>
            <RadioGroup
              inputValue={deliveryType ? deliveryType : 'true'}
              aria-label={pickupLabel}
              onChange={handleSelectPickup}
              name={pickupLabel}>
              <Radio ariaLabel={pickupLabel} value="true" />
            </RadioGroup>
          </DeliveryFilterOption>
          <Divider />

          <DeliveryFilterOption
            label={intl.formatMessage({ defaultMessage: 'Get it delivered', id: 'NZeWM0' })}
            iconName="Amenities.Booking.BookerDeliveryOnly"
            onClick={handleSelectDelivery}>
            <Icon name={'General.Caret.Right'} width={24} height={24} />
          </DeliveryFilterOption>
          <Divider />

          <DeliveryFilterOption
            label={intl.formatMessage({
              defaultMessage: 'Get it set up',
              id: 'm3N2fr',
            })}
            iconName="Amenities.Awning.Awning"
            onClick={handleSelectDeliveryAndSetup}>
            <Icon name={'General.Caret.Right'} width={24} height={24} />
          </DeliveryFilterOption>
        </>
      ) : (
        <DeliveryFilterOptionDetails
          deliveryType={deliveryType}
          deliveryAddressFromQuery={
            (deliveryAddressFromQuery as string) || (addressFromQuery as string) || ''
          }
          disableButton={disableButton}
          enableButton={enableButton}
        />
      )}
    </>
  );
};

export default DeliveryFilter;
