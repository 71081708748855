import {
  Avatar,
  Button,
  EAvatarSize,
  EButtonColorVariant,
  EHeadingStyleVariant,
  EModalFooterVariants,
  EModalSize,
  EModalVariants,
  ETextStyleVariant,
  Heading,
  Icon,
  LinkButton,
  Modal,
  Text,
  TIconName,
} from '@outdoorsyco/bonfire';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ShowMoreText from 'react-show-more-text';

import { BAYFIELD_ODN_ID, YOSEMITE_ODN_ID } from '@/constants/campgroundODN';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { ICampgroundImage } from '@/services/types/search/campgrounds/id';

import { CampgroundImages } from './CampgroundImages';

interface ICampgroundPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  images: ICampgroundImage[];
  location?: string;
  name: string;
  description: string;
  hostPhotoUrl?: string;
  isSelected: boolean;
  odnId?: string;
}

export const CampgroundPreviewModal = ({
  isOpen,
  onClose,
  onSubmit,
  images,
  location,
  name,
  description: campgroundDescription,
  hostPhotoUrl,
  isSelected,
  odnId,
}: ICampgroundPreviewModalProps) => {
  const { isMobile } = useBreakpoint();
  const intl = useIntl();

  const sortedImages = useMemo(
    () => (images?.length ? images.sort((a, b) => a.position - b.position) : []),
    [images],
  );

  const highlightsForAll: {
    title: string;
    icon: TIconName;
    description: string;
  }[] = [
    {
      icon: 'General.Wifi.On',
      title: intl.formatMessage({ defaultMessage: 'High speed WiFi', id: 'drd8TW' }),
      description: intl.formatMessage({
        defaultMessage:
          'Stay connected with our complimentary high-speed Wi-Fi hotspots around the campground.',
        id: 'TQ7Y8W',
      }),
    },
    {
      icon: 'Activities.Activities.Swimming',
      title: intl.formatMessage({ defaultMessage: 'Swimming pools', id: 'kJipp2' }),
      description: intl.formatMessage({
        defaultMessage:
          'Soak up the sun at one of our multiple swimming pools, with room for everyone.',
        id: '/bzqSD',
      }),
    },
    {
      icon: 'Amenities.GameRoom',
      title: intl.formatMessage({ defaultMessage: 'Entertainment for all', id: 'RU591N' }),
      description: intl.formatMessage({
        defaultMessage:
          'Enjoy our pickleball courts, bocci ball, club house, game room, and fitness center.',
        id: 'cAW+Q6',
      }),
    },
  ];

  const highlightsForYosemite: {
    title: string;
    icon: TIconName;
    description: string;
  }[] = [
    {
      icon: 'Activities.Activities.Swimming',
      title: intl.formatMessage({ defaultMessage: 'Community swimming pool', id: '+z+cLp' }),
      description: intl.formatMessage({
        defaultMessage: 'Soak up the sun in our community swimming pool, with room for everyone.',
        id: 'u5ttuq',
      }),
    },
    {
      icon: 'Terrain.Terrain.Lake',
      title: intl.formatMessage({ defaultMessage: 'Stunning lake close by', id: 'ibijix' }),
      description: intl.formatMessage({
        defaultMessage: 'Enjoy a day by the lake. Perfect for nature lovers and watersports!',
        id: 'VC6k6F',
      }),
    },
    {
      icon: 'Amenities.GameRoom',
      title: intl.formatMessage({ defaultMessage: 'Entertainment for all', id: 'RU591N' }),
      description: intl.formatMessage({
        defaultMessage:
          'Enjoy fishing, boating and kayaking just a short distance from the campground.',
        id: 'lNtp8H',
      }),
    },
  ];
  const highlightsForBayfield: {
    title: string;
    icon: TIconName;
    description: string;
  }[] = [
    {
      icon: 'Activities.Activities.Fishing',
      title: intl.formatMessage({ defaultMessage: 'Fishing', id: 'oo+gUt' }),
      description: intl.formatMessage({
        defaultMessage: 'Cast a line and enjoy a peaceful fishing experience at our creek on site.',
        id: 'ppG8m4',
      }),
    },
    {
      icon: 'Terrain.Terrain.Lake',
      title: intl.formatMessage({ defaultMessage: 'Nearby hot springs', id: 'wrA+l4' }),
      description: intl.formatMessage({
        defaultMessage:
          "Relax in nearby hot springs. Perfect for unwinding after a day of adventure and enjoying a soothing soak in nature's natural spa.",
        id: 'efCT2X',
      }),
    },
    {
      icon: 'Amenities.GameRoom',
      title: intl.formatMessage({ defaultMessage: 'Entertainment for all', id: 'RU591N' }),
      description: intl.formatMessage({
        defaultMessage:
          'Enjoy hiking, biking or climbing in the San Juan National Forest just a short distance from the campground.',
        id: 'qDlWFU',
      }),
    },
  ];

  let highlights;

  if (odnId === YOSEMITE_ODN_ID) {
    highlights = highlightsForYosemite;
  } else if (odnId === BAYFIELD_ODN_ID) {
    highlights = highlightsForBayfield;
  } else {
    highlights = highlightsForAll;
  }

  if (!location || !name) {
    return null;
  }

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={EModalSize.Large}
      {...(isMobile && { variant: EModalVariants.Bottom })}
      className="z-[1450]">
      <Modal.Actions />
      <Modal.Header inlineHeader>
        <Heading variant={EHeadingStyleVariant.H5}>
          <FormattedMessage defaultMessage="Get it set up" id="m3N2fr" />
        </Heading>
      </Modal.Header>
      <Modal.Content className="!mt-4">
        <div className="flex flex-col md:flex-row gap-8">
          <CampgroundImages images={sortedImages} name={name} />

          <div>
            <Avatar
              defaultBrandIcon="General.Brandmark"
              ariaLabel={name}
              imageUrl={hostPhotoUrl}
              size={EAvatarSize.Large}
            />

            <Heading variant={EHeadingStyleVariant.H4} className="mt-6">
              {name}
            </Heading>

            <Text variant={ETextStyleVariant.MediumRegular} className="mt-3 mb-6">
              <FormattedMessage
                defaultMessage="Campground in {location}"
                id="rltDTf"
                values={{ location }}
              />
            </Text>

            <ShowMoreText
              lines={3}
              more="Show more"
              less="Show less"
              className="content-css"
              anchorClass="show-more-less-clickable"
              expanded={false}
              truncatedEndingComponent={'... '}>
              <Text variant={ETextStyleVariant.MediumRegular}>{campgroundDescription}</Text>
            </ShowMoreText>
          </div>
        </div>
        <div className="flex flex-col p-8 my-8 md:flex-row md:justify-between gap-8 rounded-2xl bg-primary-30">
          {highlights.map(({ title, icon, description }) => (
            <div key={title}>
              <Icon name={icon} width={24} height={24} />
              <Text variant={ETextStyleVariant.MediumBold} className="my-2 md:my-4">
                {title}
              </Text>

              <Text variant={ETextStyleVariant.MediumRegular}>{description}</Text>
            </div>
          ))}
        </div>
      </Modal.Content>
      <Modal.Divider />
      <Modal.Footer variant={EModalFooterVariants.Actions}>
        <LinkButton
          label={intl.formatMessage({ defaultMessage: 'Back', id: 'cyR7Kh' })}
          onClick={onClose}
        />
        {!isSelected && (
          <Button
            label={intl.formatMessage({ defaultMessage: 'Set up here', id: 'SCQOms' })}
            onClick={handleSubmit}
            variant={EButtonColorVariant.Primary}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
