import { createSelector } from 'reselect';

import { UNITS } from '@/constants/units';
import { TRootState } from '@/redux/rootReducer';

export type TRadius = {
  radius: number;
  unit: UNITS;
};

export const getDelivery = createSelector<
  TRootState,
  TRootState['delivery'],
  TRootState['delivery']
>(
  state => state.delivery,
  data => data,
);

export const getDeliveryRadius = createSelector<
  TRootState,
  TRootState['listing']['data'],
  TRadius | undefined
>(
  state => state.listing.data,
  listing => {
    return listing
      ? {
          radius: listing.delivery_radius,
          unit: listing.delivery_radius_unit,
        }
      : undefined;
  },
);

export const getDeliveryCostPerMile = (state: TRootState) => {
  const listing = state.listing.data;
  return listing?.delivery_usage_item.tiers
    ? listing.delivery_usage_item.tiers[0]?.price
    : undefined;
};

export const getMeetsMinDeliveryCostReq = (state: TRootState) => {
  const deliveryCostPerMile = getDeliveryCostPerMile(state || {});
  const { data } = getDelivery(state);
  const { estimated_distance: distanceFromLocation, price: deliveryCost } = data || {};
  const actualDeliveryCost = deliveryCostPerMile && distanceFromLocation * deliveryCostPerMile;
  return actualDeliveryCost === deliveryCost;
};
