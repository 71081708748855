import React from 'react';

import { GuidedSearchComponentField } from './GuidedSearchComponentField';
import { TGuidedSearchComponent } from './types';

type TGuidedSearchComponentProps = TGuidedSearchComponent & {
  values: Record<string, undefined | string | string[]>;
  onChange: (fieldId: string, value?: string | string[]) => void;
};

export const GuidedSearchComponent = ({
  fields,
  values,
  onChange,
}: TGuidedSearchComponentProps) => {
  return (
    <div className="flex flex-col gap-6">
      {fields.map(field => {
        return (
          <GuidedSearchComponentField
            key={field.id}
            value={field.id in values ? values[field.id] : undefined}
            onChange={value => onChange(field.id, value)}
            {...field}
          />
        );
      })}
    </div>
  );
};
