export const getYoutubeVideoId = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  // @ts-expect-error fixable: unchecked index access
  return match && match[7].length == 11 ? match[7] : false;
};

export const getYoutubePreviewImage = (url: string) => {
  const videoId = getYoutubeVideoId(url);

  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};
