import React from 'react';
import { useSelector } from 'react-redux';

import { TRootState } from '@/redux/rootReducer';

import FilterPreviewResults, {
  TFilterPreviewState,
} from '../FilterPreviewResults/FilterPreviewResults';

interface IProps {
  onSubmit?: () => void;
  isDisabled?: boolean;
}

const FilterPreviewResultsContainer: React.FC<IProps> = props => {
  const { isMetaLoading, errorMeta, metaPreview } = useSelector<TRootState, TRootState['search']>(
    state => state.search,
  );
  let state: TFilterPreviewState = 'idle';
  if (isMetaLoading) state = 'loading';
  if (errorMeta) state = 'error';
  const total = metaPreview?.total !== undefined ? metaPreview?.total : null;

  return <FilterPreviewResults {...props} state={state} total={total} />;
};

export default FilterPreviewResultsContainer;
