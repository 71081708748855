import React from 'react';

import Divider from '@/components/switchback/Divider/Divider';
import Text from '@/components/switchback/Text/Text';

interface IProps {
  divider?: boolean;
  titleButton?: React.ReactNode;
  title?: string;
  ['data-testid']?: string;
}

const FilterTitle: React.FC<{ title: string }> = ({ title }) => (
  <Text className="my-4 text-gray-900 highlight autoType600" type="block">
    {title}
  </Text>
);

const FilterWithDivider: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  divider = true,
  titleButton,
  title,
  ['data-testid']: testId,
}) => {
  return (
    <div data-testid={testId} className="mx-3 md:mt-3">
      {title && (
        <div className="flex justify-between">
          <FilterTitle title={title} />
          {titleButton ?? null}
        </div>
      )}
      {children}
      {divider && <Divider className="mt-6 mb-2 md:mt-5 md:-mx-8" />}
    </div>
  );
};

export default FilterWithDivider;
