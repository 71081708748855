import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import { getBookingOrQuoteLocale } from '@/redux/selectors/bill/data';
import { getDeliveryRadius } from '@/redux/selectors/listing/delivery';
import { fetchDrivingRoutes } from '@/services/mapboxApiRequest';
import { EDeliveryOption } from '@/services/types/core/delivery.types';
import { ILocation } from '@/services/types/search/rentals/id';
import { kmToMiles } from '@/utility/measurements';

import { getDeliveryCost, TUpdateDeliveryCostActions } from '../delivery';

type TWithinRange = 'INVALID' | 'TRUE' | 'FALSE';

interface ILatLong {
  lat?: number | undefined;
  lng?: number | undefined;
}

export const updateDeliveryCost =
  (
    listingLocation: ILocation | undefined,
    deliveryLocation: ILatLong,
    deliveryStationary: EDeliveryOption,
  ): ThunkAction<Promise<TWithinRange>, TRootState, void, TUpdateDeliveryCostActions> =>
  async (dispatch, getState) => {
    if (!deliveryLocation || !listingLocation) return 'INVALID';
    const state = getState();
    const coordinates = `${listingLocation?.lng},${listingLocation?.lat};${deliveryLocation?.lng},${deliveryLocation.lat}`;

    return fetchDrivingRoutes(coordinates).then(res => {
      if (res.code !== 'Ok' || res.routes.length === 0) return 'FALSE';
      const { distance_unit: distanceUnit } = getBookingOrQuoteLocale(state);
      const deliveryRadius = getDeliveryRadius(state);
      const drivingDistance =
        res && res?.routes && res?.routes[0]?.distance ? res.routes[0].distance / 1000 : 0;
      const covertedDistance =
        distanceUnit === 'miles' ? kmToMiles(drivingDistance) : drivingDistance;
      const parsedDistance =
        distanceUnit === 'miles'
          ? parseFloat(covertedDistance.toFixed(1))
          : Math.ceil(covertedDistance);
      const isWithinRange =
        parsedDistance && deliveryRadius && parsedDistance < deliveryRadius.radius;

      if (isWithinRange) {
        dispatch(
          getDeliveryCost(
            deliveryLocation,
            deliveryStationary !== EDeliveryOption.MOVING,
            parsedDistance,
          ),
        );
        return 'TRUE';
      }
      return 'FALSE';
    });
  };
