import { EAmenityGroup, IAmenityGroup } from '@/constants/amenitiesFilter';
import {
  getRulesFilters,
  getRulesFiltersForCampgrounds,
  getRulesFiltersForStay,
} from '@/constants/moreFilter';

// Given an array of filter[feature] strings, return the filters that are used in the Amenities dropdown filter
export const amenitiesFromFilterFeatureArray = (
  filterFeatures: string[],
  items: Partial<Record<EAmenityGroup, IAmenityGroup>>,
): string[] => {
  return filterFeatures.filter(filterFeatureItem =>
    Object.values(items).some(group =>
      group.items.some(amenity => amenity.slug === filterFeatureItem),
    ),
  );
};

// Given an array of filter[feature] strings, return the filters that are used in the More dropdown filter
export const moreFromFilterFeatureArray = (
  filterFeatures: string[],
  rentalType?: 'rv' | 'stay' | 'campground',
): string[] =>
  filterFeatures.filter(filterFeatureItem =>
    Object.keys(
      (() => {
        if (rentalType === 'campground') return getRulesFiltersForCampgrounds();
        if (rentalType === 'stay') return getRulesFiltersForStay();
        return getRulesFilters();
      })(),
    ).some(key => key === filterFeatureItem),
  );
