import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Histogram from '@/components/switchback/Histogram/Histogram';
import RangeSlider from '@/components/switchback/RangeSlider/RangeSlider';
import TextInput from '@/components/switchback/TextInput';
import { STRINGS } from '@/constants/strings';
import { formatCurrency } from '@/utility/currency';
import { clamp } from '@/utility/numbers';

interface IProps {
  currency: string;
  histogramValues: number[];
  minValue?: number;
  maxValue: number;
  defaultValue: [number, number];
  show: boolean;
  onChange: (minValue: number, maxValue: number) => void;
  onChangeCommitted?: (minValue: number, maxValue: number, fromInput?: boolean) => void;
}

const PriceFilter: React.FC<IProps> = ({
  currency,
  defaultValue,
  histogramValues,
  minValue = 0,
  maxValue,
  show,
  onChange,
  onChangeCommitted,
}) => {
  const currencyFormat = useMemo(() => ({ currency, digits: 0 }), [currency]);
  const getFormatValue = useCallback(
    (value: number) => {
      const inbetweenValue = clamp(value, minValue, maxValue);
      return `${formatCurrency({
        priceInCents: inbetweenValue || 0,
        ...currencyFormat,
      })}${value >= maxValue ? STRINGS.PLUS : ''}`;
    },
    [currencyFormat, maxValue, minValue],
  );
  const [value, setValue] = useState<[number, number]>(defaultValue || [minValue, maxValue]);
  const [currencyValue, setCurrencyValue] = useState(
    (defaultValue || [minValue, maxValue]).map(val => getFormatValue(val)),
  );
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const handleChangeSlider = (newValue: number | number[]) => {
    if (
      !Array.isArray(newValue) ||
      typeof newValue[0] === 'undefined' ||
      typeof newValue[1] === 'undefined'
    ) {
      return;
    }

    setValue([newValue[0], newValue[1]]);
    onChange?.(newValue[0] || 0, newValue[1] || 100);
  };

  const handleChangeCommittedSlider = (newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    onChangeCommitted?.(newValue[0] || 0, newValue[1] || 100);
  };

  const handleBlurInput = (newValue: string, index: 0 | 1) => {
    const valueNumber =
      clamp(Math.round(Number(newValue.replace(/[^0-9.-]+/g, ''))), minValue, maxValue) * 100;

    const targetValue: [number, number] = [value[0], value[1]];
    targetValue[index] = valueNumber;

    const newCurrencyValue = Array.from(currencyValue);
    newCurrencyValue[index] = newValue;
    setCurrencyValue(newCurrencyValue);
    if (isEqual(targetValue, value)) return;

    setValue(targetValue);
    onChange?.(targetValue[0], targetValue[1]);
    onChangeCommitted?.(targetValue[0] || minValue, targetValue[1] || maxValue, true);
  };

  const handleBlurMin = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleBlurInput(event.currentTarget.value, 0);
  };

  const handleBlurMax = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleBlurInput(event.currentTarget.value, 1);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: 0 | 1) => {
    handleBlurInput(event.currentTarget.value, index);
  };

  // debounce update view value
  useEffect(() => {
    timeoutRef.current && window.clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setCurrencyValue(value.map(val => getFormatValue(val)));
    }, 750);

    return () => {
      timeoutRef.current && window.clearTimeout(timeoutRef.current);
    };
  }, [value, getFormatValue]);

  // we want to update from default value whenever filter opened
  useEffect(() => {
    if (show) {
      setValue(defaultValue);
      setCurrencyValue(defaultValue.map(val => getFormatValue(val)));
    }
  }, [show, defaultValue, getFormatValue]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-6 md:mb-8">
        <TextInput
          className="w-full md:w-28"
          name="price-filter-min"
          onBlur={handleBlurMin}
          onChange={e => handleChange(e, 0)}
          value={currencyValue[0]}
        />
        <span className="mx-4 autoType400">-</span>
        <TextInput
          className="w-full md:w-28"
          name="price-filter-max"
          onBlur={handleBlurMax}
          onChange={e => handleChange(e, 1)}
          value={currencyValue[1]}
        />
      </div>
      <div className="h-20 px-7">
        <Histogram
          values={histogramValues}
          minValue={minValue}
          maxValue={maxValue}
          highlightRange={{
            min: value[0],
            max: value[1],
          }}
        />
      </div>
      <div className="md:mb-4">
        <RangeSlider
          onChange={handleChangeSlider}
          onChangeCommitted={handleChangeCommittedSlider}
          minValue={minValue}
          maxValue={maxValue}
          defaultValue={value}
          step={100}
        />
      </div>
    </div>
  );
};

export default PriceFilter;
