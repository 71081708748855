import React from 'react';
import { useSelector } from 'react-redux';

import {
  getCampsiteCampgroundSiteMapUrl,
  getLocation,
  getStayLocationDescription,
  getStayType,
  getVehicleType,
} from '@/redux/selectors/listing/location';
import { TStayType, TVehicleType } from '@/services/types/search/rentals/id';

import Location from './Location';

export const mapVehicleTypeToPin: Record<TVehicleType, string> = {
  'camper-van': 'campervan',
  a: 'class_a',
  b: 'class_b',
  c: 'class_c',
  'fifth-wheel': 'fifth-wheel',
  'folding-trailer': 'folding-trailer',
  other: 'other',
  'toy-hauler': 'toy-hauler',
  'tow-vehicle': 'trailer',
  trailer: 'trailer',
  'truck-camper': 'truck-camper',
};
const mapStayTypeToPin: Record<TStayType, string> = {
  stays: 'stays',
  cabin: 'cabin',
  'camper-rv': 'camper',
  'canvas-tent': 'canvas-tent',
  'camping-tent': 'camping-tent',
  'tiny-home': 'tiny-home',
  yurt: 'yurt',
  hut: 'hut',
  tipi: 'tipi',
  barn: 'barn',
  treehouse: 'treehouse',
  dome: 'dome',
  'a-frame': 'a-frame',
  ranch: 'ranch',
  other: 'other',
  house: 'house',
  cottage: 'cottage',
};

interface ILocationContainerProps {
  isCampsite?: boolean;
  isStay?: boolean;
}

const LocationContainer: React.FC<ILocationContainerProps> = ({ isCampsite, isStay }) => {
  const location = useSelector(getLocation);

  const vehicleType = useSelector(getVehicleType);
  const stayType = useSelector(getStayType);
  const stayLocationDescription = useSelector(getStayLocationDescription);
  const siteMapUrl = useSelector(getCampsiteCampgroundSiteMapUrl);

  if (!location) {
    return null;
  }

  return (
    <Location
      listingLocation={location}
      stayLocationDescription={stayLocationDescription}
      lat={location.lat}
      lon={location.lng}
      pin={`https://d1o5877uy6tsnd.cloudfront.net/img/location-map-pins/${
        isCampsite
          ? 'campground'
          : isStay
            ? (stayType && mapStayTypeToPin[stayType]) || mapStayTypeToPin.cabin
            : `map-pin-${
                (vehicleType && mapVehicleTypeToPin[vehicleType]) || mapVehicleTypeToPin.c
              }-v2`
      }.png`}
      siteMapUrl={siteMapUrl}
    />
  );
};

export default LocationContainer;
