import { PopoverProps } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Popup from '@/components/switchback/Popup/Popup';
import { breakpoint } from '@/constants/breakpoint';
import KEY from '@/constants/key';
import useWindowSize from '@/hooks/useWindowSize';
import { hasPressed } from '@/utility/accessibility';

import AutocompleteMobile from './AutocompleteMobile';
import AutocompleteOptions, { IAutocompleteOption } from './AutocompleteOptions';

export interface IProps {
  anchorEl?: Element | null;
  inputLabel: string;
  options?: IAutocompleteOption[];
  optionTestId?: string;
  panelTestId?: string;
  title?: string;
  popupOptions?: Partial<PopoverProps>;
  onChangeMobileInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onToggle?: (isOpen: boolean) => void;
  onDismiss?: () => void;
  onSelectOption?: (options: IAutocompleteOption) => void;
  mobileInputPlaceholder?: string;
  alert?: React.ReactNode;
  popupClassName?: string;
  skipFocusListener?: boolean;
}

const Autocomplete: React.FC<React.PropsWithChildren<IProps>> = ({
  anchorEl,
  children,
  inputLabel,
  options = [],
  panelTestId = 'autocomplete-panel',
  optionTestId = 'autocomplete-option',
  popupOptions,
  title,
  onToggle,
  onDismiss,
  onSelectOption,
  onChangeMobileInput,
  mobileInputPlaceholder,
  alert,
  popupClassName,
  skipFocusListener = false,
}) => {
  const windowSize = useWindowSize();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const [shouldAlignOnBottom, setShouldAlignOnBottom] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const isMobile = useMemo(
    () => (windowSize?.width ? windowSize.width < breakpoint.md : false),
    [windowSize],
  );

  const handleDismiss = useCallback(() => {
    setIsOpen(false);
    setIsMobileOpen(false);
    onToggle?.(false);
    onDismiss?.();
  }, [onToggle, onDismiss]);

  const handleFocusInput = useCallback(() => {
    setIsOpen(true);
    onToggle?.(true);
    setIsMobileOpen(isMobile);
  }, [isMobile, onToggle]);

  const handleSelectOption = useCallback(
    (index: number) => {
      setSelectedSuggestionIndex(index);
      // @ts-expect-error fixable: unchecked index access
      onSelectOption?.(options[index]);
      handleDismiss();
    },
    [setSelectedSuggestionIndex, onSelectOption, handleDismiss, options],
  );

  const handleChangeMobileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeMobileInput?.(e);
  };

  const handleCloseMobile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIsMobileOpen(false);
  };

  const handleKeydownInput = useCallback(
    (e: KeyboardEvent) => {
      const ignoreKeys = [
        KEY.DOWN,
        KEY.LEFT,
        KEY.RIGHT,
        KEY.UP,
        KEY.ENTER,
        KEY.TAB,
        KEY.META,
        KEY.ALT,
        KEY.CONTROL,
        KEY.SHIFT,
        KEY.CAPS_LOCK,
      ];

      if (ignoreKeys.includes(e.key)) {
        return e;
      }

      const shouldOpen = (e.currentTarget as HTMLInputElement)?.value.length > 1;
      setIsOpen(shouldOpen);
      setIsMobileOpen(isMobile && shouldOpen);
      onToggle?.(shouldOpen);
    },
    [isMobile, onToggle],
  );

  const handlePaste = useCallback(
    (e: ClipboardEvent) => {
      const text = e.clipboardData?.getData('Text') || '';
      const shouldOpen = text.length > 1;
      setIsOpen(shouldOpen);
      setIsMobileOpen(isMobile && shouldOpen);
      onToggle?.(shouldOpen);
    },
    [isMobile, onToggle],
  );

  const handleKeyboardNavigation = useCallback(
    (e: KeyboardEvent) => {
      if (!isOpen && !isMobileOpen) {
        return;
      }

      if (hasPressed(e).ESCAPE || hasPressed(e).TAB) {
        e.preventDefault();
        handleDismiss();
      }
    },
    [isOpen, isMobileOpen, handleDismiss],
  );

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
    onToggle?.(false);
  }, [onToggle]);

  useEffect(() => {
    const button = buttonRef.current;
    const inputField = inputRef.current;

    button?.addEventListener('click', handleFocusInput);
    inputField?.addEventListener('click', handleFocusInput);
    inputField?.addEventListener('keydown', handleKeydownInput);
    inputField?.addEventListener('paste', handlePaste);
    if (!skipFocusListener) {
      inputField?.addEventListener('focus', handleFocusInput);
    }

    return () => {
      button?.removeEventListener('click', handleFocusInput);
      inputField?.removeEventListener('click', handleFocusInput);
      inputField?.removeEventListener('keydown', handleKeydownInput);
      inputField?.removeEventListener('paste', handlePaste);
      if (!skipFocusListener) {
        inputField?.removeEventListener('focus', handleFocusInput);
      }
    };
  }, [handleFocusInput, handleKeydownInput, handlePaste, skipFocusListener]);

  useEffect(() => {
    if ((isOpen && options.length) || isMobileOpen) {
      window.addEventListener('keydown', handleKeyboardNavigation);
    } else {
      window.removeEventListener('keydown', handleKeyboardNavigation);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyboardNavigation);
    };
  }, [isOpen, isMobileOpen, options, handleKeyboardNavigation]);

  useEffect(() => {
    if (skipFocusListener) {
      return;
    }

    const inputField = inputRef.current;

    if (isMobileOpen) {
      inputField?.removeEventListener('focus', handleFocusInput);
    } else {
      inputField?.addEventListener('focus', handleFocusInput);
    }
  }, [isMobileOpen, handleFocusInput, skipFocusListener]);

  /**
   * Close mobile and desktop popups in case of a breakpoint change
   */
  useEffect(() => {
    setIsOpen(false);
    setIsMobileOpen(false);
    onToggle?.(false);
  }, [isMobile, setIsOpen, setIsMobileOpen, onToggle]);

  /**
   * Set desktop popup position and size
   */
  useEffect(() => {
    if ((!buttonRef.current && !inputRef.current) || !windowSize.height || !options?.length) {
      return;
    }

    const anchor = anchorEl || inputRef.current || buttonRef.current;

    const {
      top: inputTop,
      bottom: inputBottom,
      width: inputWidth,
    } = (anchor as Element).getBoundingClientRect();
    const optionHeight = 72;
    const contentHeight = optionHeight * options.length;

    const doesFitBottom = inputBottom + contentHeight < windowSize.height;
    const doesFitTop = inputTop - contentHeight > 0;

    setContentWidth(inputWidth);
    setShouldAlignOnBottom(doesFitBottom || !doesFitTop);
  }, [windowSize, setContentWidth, options, anchorEl]);

  /**
   * Clear selected index when options change
   */
  useEffect(() => {
    setSelectedSuggestionIndex(-1);
  }, [options, setSelectedSuggestionIndex]);

  useEffect(() => {
    const inputEl = inputRef.current;

    if (!inputEl) {
      return;
    }

    inputEl.setAttribute('autocomplete', 'off');
    inputEl.setAttribute('autocorrect', 'off');
    inputEl.setAttribute('spellcheck', 'false');
    inputEl.setAttribute('aria-autocomplete', 'list');
  }, [inputRef]);

  useEffect(() => {
    const inputEl = inputRef.current;

    if (!inputEl) {
      return;
    }

    inputEl.setAttribute('aria-expanded', String(isOpen));
  }, [isOpen]);

  const mobileDefaultValue =
    options.length && selectedSuggestionIndex >= 0
      ? options[selectedSuggestionIndex]?.label
      : undefined;

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-transparent" onClick={handleClickOutside} tabIndex={-1} />
      )}
      <div
        className="relative w-full"
        ref={ref => {
          if (!ref) {
            return;
          }

          inputRef.current = ref.querySelector('input');
          buttonRef.current = ref.querySelector('button');
        }}>
        {children}
      </div>

      {isMobile ? (
        isMobileOpen && (
          <AutocompleteMobile
            defaultValue={mobileDefaultValue}
            label={inputLabel}
            panelTestId={panelTestId}
            onChange={handleChangeMobileInput}
            inputPlaceholder={mobileInputPlaceholder}
            onClose={handleCloseMobile}>
            {alert}
            {title && (
              <div className="px-4 py-2 mt-4 text-xs font-bold text-gray-500 uppercase semiHighlight">
                {title}
              </div>
            )}
            <AutocompleteOptions
              optionTestId={optionTestId}
              options={options}
              onSelectOption={handleSelectOption}
              selectedSuggestionIndex={selectedSuggestionIndex}
            />
          </AutocompleteMobile>
        )
      ) : (
        <>
          <Popup
            anchorOrigin={
              shouldAlignOnBottom
                ? {
                    vertical: 'bottom',
                    horizontal: 'left',
                  }
                : {
                    vertical: 'top',
                    horizontal: 'left',
                  }
            }
            classes={{
              root: `overflow-auto pointer-events-none ${popupClassName}`,
              paper: 'pointer-events-auto',
            }}
            className={popupClassName}
            data-testid={panelTestId}
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
            hideBackdrop
            marginThreshold={-999999}
            relativeTo={anchorEl || inputRef.current || buttonRef.current}
            show={isOpen && !!options?.length}
            transformOrigin={
              shouldAlignOnBottom
                ? {
                    vertical: 'top',
                    horizontal: 'left',
                  }
                : {
                    vertical: 'bottom',
                    horizontal: 'left',
                  }
            }
            onDismiss={handleDismiss}
            onExited={handleDismiss}
            {...popupOptions}>
            <div className="-mx-8" style={{ width: contentWidth }}>
              {title && <div className="px-8 py-2 text-gray-500 autoType200">{title}</div>}
              <AutocompleteOptions
                optionTestId={optionTestId}
                options={options}
                onSelectOption={handleSelectOption}
                selectedSuggestionIndex={selectedSuggestionIndex}
              />
            </div>
          </Popup>
        </>
      )}
    </>
  );
};

export default Autocomplete;
