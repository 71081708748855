import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import CustomLink from '@/components/switchback/Link/Link';
import { breakpoint } from '@/constants/breakpoint';
import { filterEmpty } from '@/utility/array';
import { ICoordinates, IMapSize } from '@/utility/commonDataTypes';

interface ICustomMarker extends ICoordinates {
  url?: string;
  /**
   * path of default marker icons provided by mapbox, refers to this structure {name}-{label}+{color}
   * See https://labs.mapbox.com/maki-icons/ for icons info.
   */
  pinPath?: string;
}

export type IOverlay = ICustomMarker;

export interface IViewport {
  /**
   * map center Latitude
   */
  lat: number;
  /**
   * map center Longitude
   */
  lon: number;
  /**
   * map Zoom level fom 0 to 20
   */
  zoom: number;
}

type IImageElement = React.ImgHTMLAttributes<HTMLImageElement>;

interface IProps {
  /**
   * List of Overlays to be rendered with the map.
   * See https://docs.mapbox.com/api/maps/#overlay-options for more details.
   */
  overlay?: IOverlay[];
  /**
   * List of sizes per breakpoint.
   * Each index refers to a breakpoint as per constants/breakpoint.
   * Skip a breakpoint by assigning `null` to an index.
   * See https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images#Art_direction
   */
  sourceSizes: Array<IMapSize | null>;
  /**
   * Optional map position and zoom
   */
  viewport?: IViewport;
}

const STYLE = 'outdoorsy/cjzmq1m9s115t1crxx83milfw';
const TOKEN = process.env.mapboxApiToken;

export const formatCustomMarker = ({ url, lat, lon, pinPath }: ICustomMarker) => {
  const defaultPin = 'pin-s+f74e4e';
  const pin = url ? `url-${encodeURIComponent(url)}` : pinPath || defaultPin;
  return `${pin}(${lon},${lat})`;
};

// TODO: support other overlay types
export const formatOverlay = (overlay: IOverlay[]): string =>
  overlay.map(formatCustomMarker).join(',');

export const getMapboxStaticURL = (
  { width, height, overlay, viewport }: Pick<IProps, 'overlay' | 'viewport'> & IMapSize,
  is2x?: boolean,
) => {
  const viewportString = viewport
    ? [viewport.lon, viewport.lat, viewport.zoom, 0].join(',')
    : 'auto';

  const params = [
    STYLE,
    'static',
    overlay && formatOverlay(overlay),
    viewportString,
    `${width}x${height}${is2x ? '@2x' : ''}`,
  ].filter(Boolean);

  return `https://api.mapbox.com/styles/v1/${params.join(
    '/',
  )}?access_token=${TOKEN}&logo=false&attribution=false`;
};

export const getSources = ({
  sourceSizes,
  overlay,
  viewport,
}: Pick<IProps, 'sourceSizes' | 'overlay' | 'viewport'>) => {
  const breakpoints = Object.values(breakpoint);

  return filterEmpty(
    sourceSizes.map((source, idx) => {
      if (!source) return null;
      const { width, height } = source;

      return {
        media: `(min-width: ${breakpoints[idx]}px)`,
        srcSet: [
          `${getMapboxStaticURL({ width, height, overlay, viewport })} 1x`,
          `${getMapboxStaticURL({ width, height, overlay, viewport }, true)} 2x`,
        ].join(', '),
      };
    }),
  );
};

const MapStatic: React.FC<IImageElement & IProps> = ({
  alt = '',
  overlay,
  sourceSizes,
  viewport,
  ...props
}) => {
  const defaultSource = sourceSizes.find(Boolean);
  const sources = useMemo(
    () => getSources({ sourceSizes, overlay, viewport }),
    [sourceSizes, overlay, viewport],
  );

  if (!defaultSource) {
    return null;
  }

  return (
    <figure>
      <picture>
        {sources.reverse().map(({ media, srcSet }, index) => (
          <source key={index} media={media} srcSet={srcSet} />
        ))}
        <img
          {...props}
          alt={alt}
          data-testid="map-static"
          loading="lazy"
          src={getMapboxStaticURL({ ...defaultSource, overlay, viewport })}
        />
      </picture>
      <figcaption className="mt-1 mr-1 text-right text-gray-500 autoType100 lg:autoType200 lg:mr-0">
        <span className="mr-1">
          &copy;{' '}
          <CustomLink
            href="https://www.mapbox.com/about/maps/"
            target="_blank"
            rel="noopener noreferrer">
            <FormattedMessage defaultMessage="Mapbox" id="zeDMyV" />
          </CustomLink>
        </span>
        <span className="mx-1">
          &copy;{' '}
          <CustomLink
            href="http://www.openstreetmap.org/copyright"
            target="_blank"
            rel="noopener noreferrer">
            <FormattedMessage defaultMessage="OpenStreetMap" id="mJKhsJ" />
          </CustomLink>
        </span>
        <span className="ml-1">
          <CustomLink
            href="https://www.mapbox.com/map-feedback/"
            target="_blank"
            rel="noopener noreferrer">
            <FormattedMessage defaultMessage="Improve this map" id="eSBH72" />
          </CustomLink>
        </span>
      </figcaption>
    </figure>
  );
};

export default React.memo(MapStatic);
