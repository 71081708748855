import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { formatListingLocation } from '@/components/bill/BillModuleDelivery/BillModuleDelivery';
import { ButtonShape } from '@/components/switchback/Button/Button';
import { ButtonWithLink } from '@/components/ui/BillModule/ButtonWithLink/ButtonWithLink';
import { getShortUnit } from '@/constants/units';
import { getBookingOrQuoteCurrency } from '@/redux/selectors/bill/data';
import { getDeliveryCostPerMile, getDeliveryRadius } from '@/redux/selectors/listing/delivery';
import { EDeliveryOption } from '@/services/types/core/delivery.types';
import { ILocation } from '@/services/types/search/rentals/id';
import { formatCurrency } from '@/utility/currency';
import { formatMeasurement } from '@/utility/measurements';

interface IBillExpandedDeliveryButtonsProps {
  listingLocation?: ILocation;
  onPickupSubmit: () => void;
  handleOpenDeliveryModal: (deliveryType: EDeliveryOption) => void;
}

export const BillExpandedDeliveryButtons = ({
  listingLocation,
  onPickupSubmit,
  handleOpenDeliveryModal,
}: IBillExpandedDeliveryButtonsProps) => {
  const formattedListingLocation = formatListingLocation(listingLocation);
  const intl = useIntl();

  const currency = useSelector(getBookingOrQuoteCurrency);
  const deliveryRadius = useSelector(getDeliveryRadius);
  const deliveryCostPerMile = useSelector(getDeliveryCostPerMile);
  const costPerMile = deliveryCostPerMile
    ? formatCurrency({ priceInCents: deliveryCostPerMile, currency, digits: 2 })
    : undefined;

  if (!formattedListingLocation || !deliveryRadius) return null;

  return (
    <>
      <Text variant={ETextStyleVariant.MediumBold} className="mb-4">
        <FormattedMessage defaultMessage="Getting the RV" id="17L9De" />
      </Text>

      <ButtonWithLink
        text={<FormattedMessage defaultMessage="Pick it up" id="uCaDsz" />}
        note={formattedListingLocation}
        linkText={<FormattedMessage defaultMessage="Free" id="tf1lIh" />}
        onClick={() => onPickupSubmit()}
        className="mb-2"
        noteClassName="text-primary-base"
        hideIcon
        shape={ButtonShape.rectangle}
      />

      <ButtonWithLink
        text={intl.formatMessage({ defaultMessage: 'Get it delivered', id: 'NZeWM0' })}
        note={intl.formatMessage(
          {
            defaultMessage: 'Within {distance} radius',
            id: 'ZBjztY',
          },
          {
            distance: formatMeasurement(deliveryRadius.radius, {
              unit: deliveryRadius.unit,
              long: true,
            }),
          },
        )}
        linkText={intl.formatMessage(
          { defaultMessage: '{costPerMile} / {unit}', id: 'vTIXjG' },
          { costPerMile, unit: getShortUnit(deliveryRadius.unit) },
        )}
        onClick={() => handleOpenDeliveryModal(EDeliveryOption.MOVING)}
        className="mb-2"
        shape={ButtonShape.rectangle}
        noteClassName="text-primary-base"
      />

      <ButtonWithLink
        text={intl.formatMessage({ defaultMessage: 'Get it set up', id: 'm3N2fr' })}
        note={intl.formatMessage(
          {
            defaultMessage: 'Within {distance} radius',
            id: 'ZBjztY',
          },
          {
            distance: formatMeasurement(deliveryRadius.radius, {
              unit: deliveryRadius.unit,
              long: true,
            }),
          },
        )}
        linkText={intl.formatMessage(
          { defaultMessage: '{costPerMile} / {unit}', id: 'vTIXjG' },
          { costPerMile, unit: getShortUnit(deliveryRadius.unit) },
        )}
        onClick={() => handleOpenDeliveryModal(EDeliveryOption.STATIONARY)}
        shape={ButtonShape.rectangle}
        noteClassName="text-primary-base"
      />
    </>
  );
};
