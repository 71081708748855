import { IDrivingRouteRes } from './types/mapbox/mapboxApi';

const mapboxApiToken = process.env.mapboxApiToken;

/**
 *
 * @param coordinates {string} lat long of the two locations
 */
export function fetchDrivingRoutes(coordinates: string): Promise<IDrivingRouteRes> {
  const EMPTY_RES: IDrivingRouteRes = {
    code: 'ProfileNotFound',
    routes: [],
    waypoints: [],
  };

  return fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?access_token=${mapboxApiToken}`,
  )
    .then(res => res.json())
    .then((res: IDrivingRouteRes) => (res.code === 'Ok' ? res : EMPTY_RES))
    .catch(() => EMPTY_RES);
}
