import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

interface DeliveryModalContextType {
  isSetupDelivery: boolean;
  isDeliveryModalOpen: boolean;
  openDeliveryModal: () => void;
  closeDeliveryModal: () => void;
  openSetupDeliveryModal: () => void;
}
const DeliveryModalContext = createContext<DeliveryModalContextType | undefined>(undefined);

export const useDeliveryModalContext = () => {
  const context = useContext(DeliveryModalContext);
  if (!context) {
    throw new Error('useModal must be used within a DeliveryModalProvider');
  }
  return context;
};

interface IDeliveryModalProviderProps {
  children: ReactNode;
}

export const DeliveryModalProvider = ({ children }: IDeliveryModalProviderProps) => {
  const [isDeliveryModalOpen, setDeliveryModalOpen] = useState(false);

  const [isSetupDelivery, setIsSetupDelivery] = useState(false);

  const openSetupDeliveryModal = useCallback(() => {
    setIsSetupDelivery(true);
    setDeliveryModalOpen(true);
  }, []);

  const openDeliveryModal = useCallback(() => {
    setDeliveryModalOpen(true);
  }, []);

  const closeDeliveryModal = useCallback(() => {
    setDeliveryModalOpen(false);
    setIsSetupDelivery(false);
  }, []);
  return (
    <DeliveryModalContext.Provider
      value={{
        isDeliveryModalOpen,
        openDeliveryModal,
        closeDeliveryModal,
        isSetupDelivery,
        openSetupDeliveryModal,
      }}>
      {children}
    </DeliveryModalContext.Provider>
  );
};
