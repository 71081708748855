import {
  ELinkColorVariant,
  ELinkVariant,
  ETextStyleVariant,
  Link,
  Modal,
} from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';

import FilterPreviewResultsContainer from '../FilterPreviewResultsContainer/FilterPreviewResultsContainer';
import FilterResetButton from '../FilterResetButton/FilterResetButton';

interface IProps {
  hasFooter?: boolean;
  onReset?: () => void;
  onSubmit?: () => void;
  showBackButton?: boolean;
  onBack?: () => void;
  isDisabled?: boolean;
}

const FilterModalContent: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  hasFooter = true,
  onReset,
  onSubmit,
  showBackButton,
  onBack,
  isDisabled,
}) => {
  const intl = useIntl();
  const hasBack = showBackButton && onBack;

  return (
    <>
      <Modal.Content className="h-auto m-0">{children}</Modal.Content>
      <Modal.Divider />
      <Modal.Footer className="p-0">
        {hasFooter && (
          <div
            className={`flex items-center justify-${
              hasBack || onReset ? 'between' : 'end'
            } w-full h-full px-6 py-4`}>
            {hasBack ? (
              <Link
                variant={ELinkVariant.Underline}
                onClick={onBack}
                colorVariant={ELinkColorVariant.Primary}
                textVariant={ETextStyleVariant.MediumBold}
                label={intl.formatMessage({ defaultMessage: 'Back', id: 'cyR7Kh' })}
              />
            ) : (
              onReset && <FilterResetButton onReset={onReset} />
            )}
            <FilterPreviewResultsContainer onSubmit={onSubmit} isDisabled={isDisabled} />
          </div>
        )}
      </Modal.Footer>
    </>
  );
};

export default FilterModalContent;
