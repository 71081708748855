import { ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Button, {
  ButtonSize,
  ButtonVariants,
  TButtonProps,
} from '@/components/switchback/Button/Button';
import Icon from '@/components/switchback/Icon/Icon';
import { ICONS } from '@/components/switchback/Icon/Icon.constants';

import css from './ButtonWithLink.module.css';

/**
 * @example <ButtonWithLink text="Delivery" note="Within 200 mile radius" linkText="Select" />
 */

export type TButtonWithLinkProps = {
  text: ReactNode;
  /** right aligned text */
  linkText?: ReactNode;
  /** short description below text */
  note?: string;
  variant?: ButtonVariants;
  noteClassName?: string;
  hideIcon?: boolean;
} & TButtonProps;

export const ButtonWithLink = ({
  text,
  linkText,
  note,
  variant = ButtonVariants.grayOutlined,
  className,
  noteClassName,
  hideIcon,
  ...btnProps
}: TButtonWithLinkProps) => {
  return (
    <Button
      size={ButtonSize.large}
      className={classNames(className, css.button)}
      variant={variant}
      {...btnProps}>
      <div className="flex items-center w-full">
        <div className="text-left grow">
          <Text variant={ETextStyleVariant.MediumBold}>{text}</Text>
          {note && <div className={`text-gray-300 text-300 ${noteClassName}`}>{note}</div>}
        </div>
        {!btnProps.loading && (
          <span className="flex items-center ml-1 text-green-700 highlight">
            <span className="text-sm text-gray-900">{linkText}</span>
            {!hideIcon && (
              <Icon className="ml-2 text-gray-900 transform rotate-180" name={ICONS.CARET_LARGE} />
            )}
          </span>
        )}
      </div>
    </Button>
  );
};
