import { EToastVariant } from '@outdoorsyco/bonfire';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import ErrorBoundary from '@/components/ErrorBoundary';
import { GuidedSearchProvider } from '@/components/guided-search/GuidedSearchContext';
import { GuidedSearchModal } from '@/components/guided-search/GuidedSearchModal';
import { useToast } from '@/hooks/useToast';
import { trackGuidedSearchSelected } from '@/services/analytics/search';

import HelpMeSearchButton from './HelpMeSearchButton';

const HelpMeSearch: React.FC = () => {
  const intl = useIntl();
  const { showToast } = useToast();
  const [showGuidedSearchModal, setShowGuidedSearchModal] = useState(false);

  return (
    <GuidedSearchProvider onApplySelection={() => setShowGuidedSearchModal(false)}>
      <div className={`flex items-center h-11 md:pr-2`}>
        <div className={`md:pr-6 md:border-r md:border-neutral-20`}>
          <HelpMeSearchButton
            onClick={() => {
              setShowGuidedSearchModal(true);
              trackGuidedSearchSelected({ source: 'search_button' });
            }}
          />
        </div>
      </div>

      <ErrorBoundary
        fallBackComponent={null}
        onComponentDidCatch={() => {
          setShowGuidedSearchModal(false);

          showToast(
            EToastVariant.Error,
            intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
            intl.formatMessage({
              defaultMessage: 'Something went wrong, please try again.',
              id: '8GvVWZ',
            }),
          );
        }}>
        <GuidedSearchModal
          isOpen={showGuidedSearchModal}
          onClose={() => setShowGuidedSearchModal(false)}
          className="z-[1500]" // to cover header too
        />
      </ErrorBoundary>
    </GuidedSearchProvider>
  );
};

export default HelpMeSearch;
